import { DocumentStatus } from "../types/Document";

export const DOCUMENT_STATUS_NAMES: Record<DocumentStatus, string> = {
  [DocumentStatus.Draft]: "Draft",
  [DocumentStatus.InReview]: "In Review",
  [DocumentStatus.AwaitingClientSignature]: "Awaiting Client Signer Signature",
  [DocumentStatus.AwaitingPwCSignature]: "Awaiting PwC Signer Signature",
  [DocumentStatus.Completed]: "Completed",
  [DocumentStatus.Expired]: "Expired",
  [DocumentStatus.AwaitingPaperSignature]: "Awaiting Paper Signature",
};
