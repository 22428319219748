import { Button } from "@appkit4/react-components";
import { createColumnHelper } from "@tanstack/react-table";
import { EditorNote } from "./types";
import styles from "../EditorNotes.module.scss";

const columnHelper = createColumnHelper<EditorNote>();

export const getTableColumns = (
  onEdit: (note: EditorNote) => void,
  onDelete: (noteId: string) => void
) => [
  {
    id: "title",
    size: 500,
    header: () => "Title",
    cell: (row) => <div>{row.row.original.title}</div>,
  },
  {
    id: "message",
    header: () => "Note",
    cell: (row) => (
      <div
        className={styles.notePreview}
        dangerouslySetInnerHTML={{ __html: row.row.original.message }}
      ></div>
    ),
  },
  columnHelper.accessor("id", {
    header: () => <span>Actions</span>,
    size: 160,
    cell: ({ row }) => (
      <div className="ap-flex">
        {/* commented out until we get subtemplates duplicates sorted out
        <Button
          add
          kind="text"
          icon="icon-hyperlink-fill"
          className="ap-text-neutral-10"
          onClick={() => {}}
        /> */}
        <Button
          add
          kind="text"
          icon="icon-edit-outline"
          className="ap-text-neutral-10"
          onClick={() => onEdit(row.original)}
        />
        <Button
          add
          kind="text"
          icon="icon-delete-outline"
          className="ap-text-neutral-10"
          onClick={() => onDelete(row.original.id)}
        />
      </div>
    ),
  }),
];
