import { useEffect, useState } from "react";
import { Api } from "../api/apiHelper";
import { getSettingByCode } from "../api/endpoints/settings";

export const useUnderMaintenance = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  useEffect(() => {
    Api.get(getSettingByCode("UNDER_MAINTENANCE"))
      .then((setting) => {
        const isInMaintenance = setting.value as boolean;
        setIsUnderMaintenance(isInMaintenance);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return { isLoading, isUnderMaintenance };
};
