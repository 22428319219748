import { COLORS } from "../../constants/colors";
import { DocumentStatus } from "../../types/Document";
import {
  CountsByStatusGroup,
  DOCUMENT_TILE_ALL,
  DOCUMENT_TILE_SIGNATURES,
  TileName,
} from "./types";

const getTileLabel = (name: TileName) => {
  switch (name) {
    case DocumentStatus.Draft:
      return "In Draft";
    case DocumentStatus.InReview:
      return "In Review";
    case DocumentStatus.AwaitingPwCSignature:
      return "Awaiting PwC Signer Signature";
    case DocumentStatus.AwaitingClientSignature:
      return "Awaiting Client Signer Signature";
    case DocumentStatus.Completed:
      return "Completed";
    case DocumentStatus.Expired:
      return "Expired";
    case DOCUMENT_TILE_ALL:
      return "All Documents"; 
    case DOCUMENT_TILE_SIGNATURES:
      return "Awaiting Signature";
    default:
      return "";
  }
};

const getTileColor = (name: TileName) => {
  switch (name) {
    case DocumentStatus.Draft:
      return COLORS.GREY;
    case DocumentStatus.InReview:
      return COLORS.BORDO;
    case DocumentStatus.AwaitingPwCSignature:
      return COLORS.ROUSE;
    case DocumentStatus.AwaitingClientSignature:
      return COLORS.YELLOW;
    case DocumentStatus.Completed:
      return COLORS.GREEN;
    case DOCUMENT_TILE_ALL:
      return COLORS.ORANGE;
    case DOCUMENT_TILE_SIGNATURES:
      return COLORS.YELLOW;
    default:
      return COLORS.BLACK;
  }
};

export default function createTilesView(
  countByStatusGroup: CountsByStatusGroup
) {
  return !!countByStatusGroup
    ? Object.entries(countByStatusGroup).map(([key, value]) => ({
        id: key,
        name: getTileLabel(key as TileName),
        color: getTileColor(key as TileName),
        count: value,
      }))
    : [];
}
