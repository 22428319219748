import { Row } from "@tanstack/react-table";
import { Option } from "../../../types/Dropdown";

export type BasicRowProps = {
  id: string;
};

export enum CustomColumn {
  DRAGGABLE = "draggable",
}

export enum EditableCellType {
  INPUT = "string",
  MULTI_SELECT = 'multiSelect',
  CHECKBOX = 'checkbox'
}

export type CustomColumnMeta = {
  type: EditableCellType;
  options: Option[];
  isEditable: boolean;
  className?: string;
  hasTooltip?: boolean;
};

export type ActionsColumnMeta<T> = {
  actions: {
    onClick: (row: T) => void;
    iconName: string;
    isDisabled?: boolean;
  }[];
  className: CustomColumnMeta["className"];
};

export type CustomTableMeta<T> = {
  updateData: (row: Row<T>, columnId: string, value: FieldValue) => void;
};

export type FieldValue = string | Option[] | boolean;

export type FieldProps = {
  value: FieldValue;
  onChange: (value: FieldValue) => void;
  onUpdateCellValue: (value?: FieldValue) => void;
  autoFocus?: boolean;
  options?: Option[]
};
