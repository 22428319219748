import { NoResults } from "../NoResults";
import imgSrc from "../../assets/GracefulError.png";

export const UnderMaintenance = () => {
  return (
    <NoResults
      mainMessage="Application Offline"
      additionalMessage="Create For Tax is currently unavailable due to system maintenance."
      icon={imgSrc}
      className="full-height"
    />
  );
};
