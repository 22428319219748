export const messages = {
  common: {
    apply: "Apply",
    searchPlaceholder: "Search...",
    selectPlaceholder: "Select",
    name: "Name",
    email: "Email address",
    role: "Role",
    actions: "Actions",
    add: "Add",
    delete: "Delete",
  },
  upload: {
    instruction: "You can upload PDF file. The max file size is 10mb.",
  },
  form: {
    required: "This field is required",
  },
};
