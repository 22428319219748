export type Setting = {
    id: string;
    name: string;
    type: SettingType;
    value: string | boolean;
}

export enum SettingType {
    Boolean = "Boolean",
    String = "String",
    Select = "Select"
}