import { useState, useEffect } from "react";

const DEFAULT_DELAY = 1000;

export const useDebouncedValue = <T>(value: T, milliSeconds?: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  const delay = milliSeconds ?? DEFAULT_DELAY;

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
