import { useEffect, useMemo, useState } from "react";
import * as _ from "lodash";
import { Button, Pagination, Search } from "@appkit4/react-components";
import { Row } from "@tanstack/react-table";
import PageHeader from "../../../components/PageHeader";
import { Api } from "../../../api/apiHelper";
import { getUsers, userRoles } from "../../../api/endpoints/users";
import Table from "../../../components/Table";
import { User, UserRow } from "../../../types/User";
import { Option } from "../../../types/Dropdown";
import { errorToast, successToast } from "../../../components/Toast";
import CreateUser from "./components/CreateUser";
import { createUserColumns } from "./helpers/createUserTableColumns";
import { entityToOption } from "../../../helpers/entityToOption";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder";
import { useDebouncedValue } from "../../../hooks/useDebouncedValue";
import { UserSearchParams } from "../../../types/UserSearchParams";
import Select from "../../../components/Select";
import styles from "./Users.module.scss";
import { replaceUser } from "./helpers/replaceUser";
import { ITEMS_PER_PAGE } from "./constants";
import { DataPage } from "../../../types/DataPage";

export default function Users() {
  const [users, setUsers] = useState<DataPage<UserRow> | undefined>(undefined);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [userRolesOptions, setUserRolesOptions] = useState<Option[]>([]);
  const [searchText, setSearchText] = useState("");
  const searchQuery = useDebouncedValue(searchText, searchText ? 1000 : 0);

  const [queryParams, setQueryParams] = useState<UserSearchParams>({
    searchStr: "",
    offset: 0,
    roleIds: [],
    limit: ITEMS_PER_PAGE,
  });

  const page = useMemo(
    () => queryParams.offset / ITEMS_PER_PAGE + 1,
    [queryParams]
  );

  const totalPages = useMemo(
    () => Math.ceil((users?.total || 0) / ITEMS_PER_PAGE),
    [users]
  );

  const columns = useMemo(
    () => createUserColumns(userRolesOptions),
    [userRolesOptions, searchText]
  );

  useEffect(() => {
    loadUserRoles();
  }, []);

  useEffect(() => {
    searchQuery !== queryParams.searchStr &&
      setQueryParams({
        ...queryParams,
        searchStr: searchQuery,
        offset: 0,
      });
  }, [searchQuery]);

  useEffect(() => {
    loadUsers();
  }, [queryParams]);

  const loadUsers = () => {
    setIsLoadingUsers(true);

    Api.post(getUsers, queryParams)
      .then((res) => res.json())
      .then((res) => {
        setUsers({
          ...res,
          items: res.items.map((user) => ({
            ...user,
            roles: user.roles.map(entityToOption),
          })),
        });
      })
      .catch((err) => errorToast({ message: `Failed to load users. ${err}` }))
      .finally(() => setIsLoadingUsers(false));
  };

  const loadUserRoles = () => {
    Api.get(userRoles).then((res) => {
      setUserRolesOptions(res.map(entityToOption));
    });
  };

  const openCreateUserModal = () => {
    setIsCreateUserModalOpen(true);
  };

  const createUser = (user: User) => {
    Api.post(userRoles, user)
      .then((res) => JSON.stringify(res))
      .then((id) => {
        setUsers({
          ...users,
          items: [
            ...(users?.items || []),
            {
              ...user,
              id,
              roles: userRolesOptions.filter((role) =>
                user.roleIds.includes(role.value)
              ),
            },
          ],
        } as DataPage<UserRow>);
        setIsCreateUserModalOpen(false);
        successToast({ message: "User has been created" });
      })
      .catch((err) => errorToast({ message: `Failed to create user. ${err}` }));
  };

  const handleCellEdit = (
    row: Row<UserRow>,
    columnId: string,
    value: Option[]
  ) => {
    if (columnId === "roles") {
      const roleIds = value.map((i) => i.value);

      const prevRoleIds = users?.items
        .find((user) => user.id === row.original.id)
        ?.roles.map((i) => i.value);

      if (_.isEqual(roleIds, prevRoleIds)) return;

      Api.patch(userRoles, { userId: row.original.id, roleIds })
        .then(() => {
          setUsers({
            ...users,
            items: users?.items.map(
              replaceUser({ ...row.original, roleIds }, userRolesOptions)
            ),
          } as DataPage<UserRow>);

          successToast({ message: "User has been updated" });
        })
        .catch((err) =>
          errorToast({ message: `Failed to update user. ${err}` })
        );
    }
  };

  const setSelectedRoles = (roles: Option[]) => {
    setQueryParams({ ...queryParams, roleIds: roles.map((i) => i.value) });
  };

  const setPage = (page: number) =>
    setQueryParams({ ...queryParams, offset: (page - 1) * ITEMS_PER_PAGE });

  return (
    <div className="full-height">
      <PageHeader title="User Management" />
      <div className={styles.actions}>
        <div className={styles.filter}>
          <Search
            searchValue={searchText}
            onChange={setSearchText}
            searchType="secondary"
            placeholder="Search User"
            className={styles.search}
          />
          <Select
            options={userRolesOptions}
            isMulti
            title="Filter by Role"
            onChange={(value) => setSelectedRoles(value as Option[])}
            className={styles.roleFilter}
          ></Select>
        </div>
        <Button kind="secondary" onClick={openCreateUserModal}>
          Create User
        </Button>
      </div>
      <div>
        {isLoadingUsers && <LoadingPlaceholder overlay="parent" />}
        <Table
          data={users?.items || []}
          columns={columns}
          striped
          //@ts-ignore
          onCellEdit={handleCellEdit}
        />

        <Pagination
          current={page}
          total={totalPages}
          onPageChange={(page: number) => {
            setPage(page || 1);
          }}
        />
      </div>
      {isCreateUserModalOpen && (
        <CreateUser
          onClose={() => setIsCreateUserModalOpen(false)}
          onConfirm={createUser}
          userRoles={userRolesOptions}
        />
      )}
    </div>
  );
}
