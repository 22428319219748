import { useEffect, useRef, useState } from "react";
import {
  Column,
  ColumnMeta as TanstackColumnMeta,
  Row,
  Table,
  TableMeta as TanstackTableMeta,
} from "@tanstack/react-table";
import { CustomColumnMeta, CustomTableMeta, FieldValue } from "../../types";
import Input from "./Input";
import { MultiSelect } from "./MultiSelect";
import Checkbox from "./Checkbox";

const cellTypeToInput = {
  string: Input,
  multiSelect: MultiSelect,
  checkbox: Checkbox,
};

type Props<T> = {
  getValue: () => unknown;
  row: Row<T>;
  column: Column<T>;
  table: Table<T>;
};

type ColumnMeta<T> = TanstackColumnMeta<T, string> & CustomColumnMeta;
type TableMeta<T> = TanstackTableMeta<T> & CustomTableMeta<T>;

export const EditableCell = <T extends object>({
  getValue,
  row,
  column,
  table,
}: Props<T>) => {
  const initialValue = getValue() as string;
  const [value, setValue] = useState<FieldValue>(initialValue);

  const { type, options } = column.columnDef.meta as ColumnMeta<T>;

  const onBlurHandlerRef = useRef<Function>();

  const Field = cellTypeToInput[type] || Input;

  const onChange = (value: FieldValue) => {
    setValue(value);
  };

  const onUpdateCellValue = (changedValue?: FieldValue) => {
    if (changedValue !== undefined) {
      return (table.options.meta as TableMeta<T>)?.updateData(
        row,
        column.id,
        changedValue
      );
    }
    if (value === initialValue) return;
    (table.options.meta as TableMeta<T>)?.updateData(row, column.id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    onBlurHandlerRef.current = onUpdateCellValue;
  }, [onUpdateCellValue]);

  useEffect(() => {
    return () => onBlurHandlerRef.current && onBlurHandlerRef.current();
  }, []);

  const isFirstEditableColumn =
    table
      ._getColumnDefs()
      .find((column) => (column.meta as CustomColumnMeta)?.isEditable)?.id ===
    column.id;

  return (
    <Field
      value={value}
      onChange={onChange}
      onUpdateCellValue={onUpdateCellValue}
      autoFocus={isFirstEditableColumn}
      options={options}
    />
  );
};
