export const getDocumentsApi = (clientId?: string, engagementId?: string) =>
  `/api/documents${
    clientId || engagementId
      ? `?${clientId ? `clientId=${clientId}` : ""}${
          engagementId ? `&engagementId=${engagementId}` : ""
        }`
      : ""
  }`;

export const deactivateDocument = (documentId: string) =>
  `/api/documents/${documentId}/deactivate`;

export const getStatusCounts = (clientId?: string, engagementId?: string) =>
  `/api/documents/status-counts${
    clientId || engagementId
      ? `?${clientId ? `clientId=${clientId}` : ""}${
          engagementId ? `&engagementId=${engagementId}` : ""
        }`
      : ""
  }`;

export const getAGDocumentsApi = (clientId?: string, engagementId?: string) =>
  `/api/documents/advanced-filter${
    clientId || engagementId
      ? `?${clientId ? `clientId=${clientId}` : ""}${
          engagementId ? `&engagementId=${engagementId}` : ""
        }`
      : ""
  }`;

export const getDocumentFilterMetadataApi = `/api/documents/filter-metadata`;
export const getDocumentEntitiesFilterMetadataApi = `/api/documents/filter-metadata/entities`;
export const getDocumentCreatedByFilterMetadataApi = `/api/documents/filter-metadata/created-by`;
export const getDocumentPwCSignersFilterMetadataApi = `/api/documents/filter-metadata/pwc-signers`;
export const getDocumentOFROReviewersFilterMetadataApi = `/api/documents/filter-metadata/ofro-reviewers`;
export const detDocumentUpdatedByFilterMetaDataApi =
  "/api/documents/filter-metadata/updated-by";
  
  export const getDocumentDetails = (documentId: string) =>
    `/api/documents/${documentId}`;
