import { COLORS } from "../constants/colors";
import { DocumentStatus } from "../types/Document";

export const getStatusColor = (status: DocumentStatus) => {
  switch (status) {
    case DocumentStatus.Draft:
      return COLORS.GREY;
    case DocumentStatus.InReview:
      return COLORS.BORDO;
    case DocumentStatus.AwaitingPwCSignature:
      return COLORS.ROUSE;
    case DocumentStatus.AwaitingClientSignature:
      return COLORS.YELLOW;
    case DocumentStatus.Completed:
      return COLORS.GREEN;
    case DocumentStatus.Expired:
      return COLORS.BLACK;  
    case DocumentStatus.AwaitingPaperSignature:
      return COLORS.YELLOW;
    default:
      return COLORS.BLACK;
  }
};
