export const ROUTES = {
  HOME: { name: "", path: "/" },
  ADMIN: { name: "Admin", path: "/admin" },
  USERS: { name: "Users", path: "/admin/users" },
  CATEGORIES: { name: "Categories", path: "/admin/categories" },
  GUIDANCES: { name: "Guidances", path: "/admin/guidances" },
  EDITOR_NOTES: { name: "Editor Notes", path: "/admin/editor-notes" },
  TEMPLATES: { name: "Templates", path: "/admin/templates" },
  FORMS: { name: "Forms", path: "/admin/forms" },
  CREATE_FORM: { name: "Create Form", path: "/admin/forms/create-form" },
  CREATE_TEMPLATE: {
    name: "Create Template",
    path: "/admin/templates/create-template",
  },
  DOC_BOT_TEMPLATES: {
    name: "DocBot Templates",
    path: "/admin/doc-bot-templates",
  },
  SETTINGS: { name: "Settings", path: "/admin/settings" },
};
