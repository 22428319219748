export const AVATAR_COLOR = "#D04A02";

export const COLORS = {
  GREY: "#9E9E9E",
  BORDO: "#933401",
  ROUSE: "#FD6412",
  YELLOW: "#FFB600",
  GREEN: "#2C8646",
  ORANGE: "#D04A02",
  BLACK: "#000",
  TRANSPARENT: "#0000FFCC",
  RED: "#FF0000",
};
