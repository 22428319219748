import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { AdminSection } from "../../../../../types/AdminSection";
import styles from "./Card.module.scss";

const Card = ({ title, description, icon, color, link }: AdminSection) => {
  const navigate = useNavigate();

  return (
    <div className={styles.card} onClick={() => navigate(link)}>
      <div className={styles.icon} style={{ backgroundColor: color }}>
        <span className={`Appkit4-icon icon-${icon} ap-font-24`}></span>
      </div>
      <div className={clsx("ap-typography-heading-s", styles.text)}>
        {title}
      </div>
      <div className={clsx("ap-typography-body", styles.text)}>
        {description}
      </div>
    </div>
  );
};

export default Card;
