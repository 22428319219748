import { format } from "date-fns";
import { Badge } from "@appkit4/react-components/badge";
import { getStatusColor } from "../../../helpers/getColor";
import { DOCUMENT_STATUS_NAMES } from "../../../constants/documentStatusName";
import { IDocument } from "../../../types/Document";

export default function createDetails(document: IDocument) {
  return [
    {
      id: 0,
      name: "Engagement Details",
      items: [
        {
          id: 0,
          name: "Engagement Name",
          value: document.engagementName,
        },
        {
          id: 1,
          name: "Entity/Individual",
          value: document.entityName || "-",
        },
        {
          id: 2,
          name: "Template",
          value: document.templateName,
        },
        {
          id: 3,
          name: "Created By",
          value: document.createdBy,
        },
        {
          id: 4,
          name: "Created Date",
          value: format(new Date(document.createdDate), "MM/dd/yyyy"),
        },
        {
          id: 5,
          name: "PwC Signer",
          value: document.pwCSignerName || "-",
        },
      ],
    },
    {
      id: 1,
      name: "Status Details",
      items: [
        {
          id: 0,
          name: "Status",
          value: (
            <Badge
              value={DOCUMENT_STATUS_NAMES[document.status]}
              style={{ backgroundColor: getStatusColor(document.status) }}
            />
          ),
        },
        {
          id: 1,
          name: "Last Updated",
          value: format(new Date(document.modifiedDate), "MM/dd/yyyy hh:mma"),
        },
      ],
    },
    {
      id: 2,
      name: "Docusign Details",
      items: [
        {
          id: 0,
          name: "Docusign Subject Line",
          value: document.docuSignEmailSubject || "-",
        },
      ],
    },
  ];
}
