import { Button, Input, InputNumber, Select } from "@appkit4/react-components";
import { useFormik } from "formik";
import * as _ from "lodash";
import clsx from "clsx";
import { PageFooter } from "../../../../components/PageFooter";
import PageHeader from "../../../../components/PageHeader";
import { FormType } from "../../../../types/FormType";
import { SignerRole } from "../../../../types/SignerRole";
import PDFViewer from "../../../../components/PDFViewer";
import { Entity } from "../../../../types/Entity";
import { Form, NewForm } from "../types/Form";
import { FormikErrors } from "../types/FormikErrors";
import { validationSchema } from "../helpers/validationSchema";
import { FORM_TYPE_OPTIONS } from "../constants/FormTypeOptions";
import { isExistingForm } from "../types/typeGuards";
import { createFormTag } from "../helpers/createNewForm";
import { Breadcrumb } from "../../../../components/Breadcrumb";
import { ROUTES } from "../../../../constants/routes";
import styles from "./EditForm.module.scss";

type Props = {
  initialValues: Form | NewForm;
  onClickBack: () => void;
  onSave: (values: Form | NewForm) => Promise<void>;
  formWithTags: string | null;
  onOpenModal: (modal: "preview" | "test") => void;
  formSignerRoles: Entity[];
};

export const EditFormPresenter = ({
  initialValues,
  onClickBack,
  onSave,
  onOpenModal,
  formWithTags,
  formSignerRoles,
}: Props) => {
  const formik = useFormik<Form | NewForm>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSave(values).finally(() => formik.setSubmitting(false));
    },
  });

  const isExisting = isExistingForm(initialValues);

  const pageTitle = isExisting ? initialValues.name : "Create Form";

  const changeFormType = (value: FormType) => {
    if (value === formik.values.type) return;

    if (value === FormType.Entity) {
      formik.setValues({
        ...formik.values,
        type: value,
        formTags: formik.values.formTags.filter(
          (tag) => tag.roleName !== SignerRole.CLIENT_SPOUSE
        ),
      });
    }

    if (value === FormType.Individual) {
      formik.setValues({
        ...formik.values,
        type: value,
        formTags: [
          ...formik.values.formTags,
          createFormTag(
            formSignerRoles.find(
              (role) => role.name === SignerRole.CLIENT_SPOUSE
            ) as Entity
          ),
        ],
      });
    }
  };

  return (
    <>
      <Breadcrumb
        list={[ROUTES.FORMS, { name: pageTitle }]}
        className={styles.breadcrumb}
      />
      <div className={clsx(styles.root, "scroll-bar")}>
        <PageHeader title={pageTitle} />
        <div className={styles.content}>
          {!isExisting && (
            <Select
              placeholder="Form Type"
              className="ap-mb-spacing-3"
              data={FORM_TYPE_OPTIONS}
              value={formik.values.type}
              onSelect={(value) => changeFormType(value as FormType)}
              required
            />
          )}
          <Input
            title="Form Name"
            required
            value={formik.values.name}
            onChange={(value: string) => formik.setFieldValue("name", value)}
            className="ap-mb-spacing-3"
            error={formik.touched.name && !!formik.errors.name}
          />
          <div className="ap-mb-spacing-5 ap-typography-heading-s">
            Form Coordinates
          </div>

          <div className={styles.coordinates}>
            {formik.values.formTags.map((tag, tagIdx) => {
              return (
                <div key={tag.roleId} className="ap-mb-spacing-5 ap-full-width">
                  <div
                    className={clsx(
                      styles.tile,
                      "ap-mb-spacing-3 ap-font-weight-2"
                    )}
                  >{`${tag.roleName}`}</div>

                  <div className="ap-flex ap-full-width ap-flex-gap-default">
                    {tag.coordinates.map((coordinate, idx) => (
                      <div key={coordinate.type} className="ap-full-width">
                        <div className="ap-mb-spacing-3 ap-font-weight-2">
                          {coordinate.type}
                        </div>
                        <InputNumber
                          className="ap-mb-spacing-3"
                          required
                          title={`X Coordinate`}
                          value={coordinate.x}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `formTags[${tagIdx}].coordinates[${idx}].x`,
                              Number(value)
                            )
                          }
                          error={
                            formik.touched.formTags?.[tagIdx]?.coordinates?.[idx]
                            ?.x &&
                            !!(formik.errors as FormikErrors).formTags?.[tagIdx]
                              ?.coordinates?.[idx]?.x
                          }
                        />
                        <InputNumber
                          className="ap-mb-spacing-3"
                          required
                          title={`Y Coordinate`}
                          value={coordinate.y}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `formTags[${tagIdx}].coordinates[${idx}].y`,
                              Number(value)
                            )
                          }
                          error={
                            formik.touched.formTags?.[tagIdx]?.coordinates?.[idx]
                            ?.y &&
                            !!(formik.errors as FormikErrors).formTags?.[tagIdx]
                              ?.coordinates?.[idx]?.y
                          }
                        />
                        <InputNumber
                          title="Page"
                          className="ap-mb-spacing-3"
                          required
                          value={coordinate.pageNumber}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `formTags[${tagIdx}].coordinates[${idx}].pageNumber`,
                              Number(value)
                            )
                          }
                          error={
                            formik.touched.formTags?.[tagIdx]?.coordinates?.[idx]
                            ?.pageNumber &&
                            !!(formik.errors as FormikErrors).formTags?.[tagIdx]
                              ?.coordinates?.[idx]?.pageNumber
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          {isExisting && (
            <>
              <div className="ap-mb-spacing-5 ap-typography-heading-s">
                Form Preview With Coordinates
              </div>

              <div className={clsx(styles.preview, "ap-scroll-bar")}>
                <PDFViewer file={formWithTags} />
              </div>
            </>
          )}
        </div>
        <PageFooter
          onClickBack={onClickBack}
          onSave={() => formik.handleSubmit()}
          isSaving={formik.isSubmitting}
          secondaryActions={
            <>
              {isExisting && (
                <>
                  <Button kind="secondary" onClick={() => onOpenModal("preview")}>
                    Get Preview
                  </Button>
                  <Button kind="secondary" onClick={() => onOpenModal("test")}>
                    Send For Test
                  </Button>
                </>
              )}
            </>
          }
        />
      </div>
    </>
  );
};
