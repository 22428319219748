import { MutableRefObject, useLayoutEffect, useState } from "react";

export const useOverflow = (ref: MutableRefObject<HTMLElement | null>) => {
  const [overflow, setOverflow] = useState({
    isVerticalOverflow: false,
    isHorizontalOverflow: false,
  });

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      setOverflow({
        isVerticalOverflow: current.scrollHeight > current.clientHeight,
        isHorizontalOverflow: current.scrollWidth > current.clientWidth,
      });
    }
  }, [ref]);

  return overflow;
};
