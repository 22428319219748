export const categories = "/api/categories";

export const allCategories = "/api/categories/all";

export const deleteCategory = (categoryId: string) =>
  `/api/categories/${categoryId}`;

export const subCategories = "/api/categories/sub-categories";

export const allSubCategoriesByCategoryId = (categoryId: string) =>
  `/api/categories/${categoryId}/all-sub-categories`;
