import clsx from "clsx";
import NoResultIcon from "../../assets/NoSearchResults.svg";
import styles from "./NoResults.module.scss";

type Props = {
  mainMessage: string;
  additionalMessage?: string | JSX.Element;
  icon?: string;
  className?: string;
};

export const NoResults = ({ mainMessage, additionalMessage, icon, className}: Props) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <img src={icon || NoResultIcon} alt="No Search Results" className="ap-mb-spacing-5"/>
      <h5 className="ap-typography-heading">{mainMessage}</h5>
      <div className="ap-typography-body">{additionalMessage}</div>
    </div>
  );
};
