import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import { getNavigationLink } from "../../pages/TrackDocuments/helpers/getNavigationLink";
import { Api } from "../../api/apiHelper";
import { getDocumentDetails } from "../../api/endpoints/documents";
import { HOST_APP_BASE_URL } from "../../constants/hostAppBaseUrl";
import { errorToast } from "../Toast";

type Props = {
  documentId: string;
};

export default function Redirect({ documentId }: Props) {
  const [isDocumentDetailsLoading, setIsDocumentDetailsLoading] =
    useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!documentId) return;
    setIsDocumentDetailsLoading(true);
    Api.get(getDocumentDetails(documentId))
      .then((documentDetails) =>
        navigate(
          `${HOST_APP_BASE_URL}${getNavigationLink(
            documentDetails
          )}/?documentId=${documentId}`,
          {
            replace: true,
          }
        )
      )
      .catch(() => {
        errorToast({ message: "Failed to open the document" });
      })
      .finally(() => setIsDocumentDetailsLoading(false));
  }, [documentId]);

  return (
    <>
      {isDocumentDetailsLoading && (
        <div style={{ height: "80vh" }}>
          <LoadingPlaceholder />
        </div>
      )}
    </>
  );
}
