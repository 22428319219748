import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { Form, NewForm } from "../types/Form";
import { useEffect, useState } from "react";
import { Api } from "../../../../api/apiHelper";
import {
  formById,
  formMarkTags,
  forms as formsApi,
} from "../../../../api/endpoints/forms";
import { errorToast, successToast } from "../../../../components/Toast";
import { createNewForm } from "../helpers/createNewForm";
import { DownloadFormWithTags } from "../components/DownloadFormWithTags";
import { SendForTest } from "../components/SendForTest";
import { isExistingForm } from "../types/typeGuards";
import { EditFormPresenter } from "./EditFormPresenter";
import { SIGNER_ORDER, TAG_TYPE_ORDER } from "../constants/orders";
import { useAppContext } from "../../../../contexts/AppContext";
import { FORM_SIGNER_ROLE_NAMES } from "../constants/SignerRoles";
import LoadingPlaceholder from "../../../../components/LoadingPlaceholder";
import { SignerRole } from "../../../../types/SignerRole";

export const EditForm = () => {
  const navigate = useNavigate();
  const { formId } = useParams();
  const { signerRoles } = useAppContext();

  const [form, setForm] = useState<Form | NewForm>();
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [formWithTags, setFormWithTags] = useState<string | null>(null);
  const [isLoadingFormWithTags, setIsLoadingFormWithTags] = useState(false);
  const [modal, setModal] = useState<"test" | "preview" | null>(null);

  const formSignerRoles = signerRoles.filter((role) =>
    FORM_SIGNER_ROLE_NAMES.includes(role.name as SignerRole)
  );

  useEffect(() => {
    if (formId) {
      setIsLoadingForm(true);
      Api.get(formById(formId))
        .then((data) => {
          const sortedTags = [...data.formTags];
          sortedTags
            .sort((a, b) => SIGNER_ORDER[a.roleName] - SIGNER_ORDER[b.roleName])
            .forEach((tag) =>
              tag.coordinates.sort(
                (a, b) => TAG_TYPE_ORDER[a.type] - TAG_TYPE_ORDER[b.type]
              )
            );

          setForm({
            ...data,
            formTags: sortedTags,
          });
        })
        .catch((error) =>
          errorToast({ message: `Failed to fetch form details. ${error}` })
        )
        .finally(() => setIsLoadingForm(false));
    } else {
      const newForm = createNewForm(formSignerRoles);
      setForm(newForm as NewForm);
    }
  }, [formId]);

  const onClickBack = () => navigate("/admin/forms");

  const onSave = (form: Form | NewForm) => {
    if (isExistingForm(form)) {
     return Api.put(formById(form.id), form)
        .then(() => successToast({ message: "Form has been saved" }))
        .catch((error) =>
          errorToast({ message: `Failed to save form. ${error}` })
        );
    } else {
     return Api.post(formsApi, form)
        .then((res) => res.json())
        .then((res) => {
          successToast({ message: "Form has been created" });
          navigate(`/admin/forms/${res.id}`);
        })
        .catch((error) =>
          errorToast({ message: `Failed to create form. ${error}` })
        );
    }
  };

  const confirmDownloadFormWithTags = (file: File) => {
    if (!formId) return;
    setIsLoadingFormWithTags(true);
    const formData = new FormData();
    formData.append("file", file);

    Api.postFile(formMarkTags(formId), formData)
      .then((res) => res.blob())
      .then((blob) => window.URL.createObjectURL(new Blob([blob])))
      .then((file) => setFormWithTags(file))
      .finally(() => {
        setModal(null);
        setIsLoadingFormWithTags(false);
      });
  };

  return (
    <>
      {isLoadingForm && <LoadingPlaceholder />}

      {!!form && (
        <EditFormPresenter
          initialValues={form}
          onClickBack={onClickBack}
          onSave={onSave}
          formWithTags={formWithTags}
          onOpenModal={setModal}
          formSignerRoles={formSignerRoles}
        />
      )}
      {modal === "preview" && (
        <DownloadFormWithTags
          onClose={() => setModal(null)}
          onConfirm={confirmDownloadFormWithTags}
          isLoading={isLoadingFormWithTags}
        />
      )}

      {modal === "test" && (
        <SendForTest onClose={() => setModal(null)} activeFormId={formId} />
      )}
    </>
  );
};
