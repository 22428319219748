import { Button } from "@appkit4/react-components";
import { createColumnHelper } from "@tanstack/react-table";
import { Guidance } from "../../../types/Guidance";

const columnHelper = createColumnHelper<Guidance>();

export const getTableColumns = (
  onEdit: (guidance: Guidance) => void,
  onDelete: (noteId: string) => void
) => [
  {
    id: "name",
    size: 500,
    header: () => "Name",
    cell: (row) => <div>{row.row.original.name}</div>,
  },
  columnHelper.accessor("id", {
    header: () => <span>Actions</span>,
    size: 100,
    cell: ({ row }) => (
      <div className="ap-flex">
        <Button
          add
          kind="text"
          icon="icon-edit-outline"
          className="ap-text-neutral-10"
          onClick={() => onEdit(row.original)}
        />
        <Button
          add
          kind="text"
          icon="icon-delete-outline"
          className="ap-text-neutral-10"
          onClick={() => onDelete(row.original.id)}
        />
      </div>
    ),
  }),
];