import { Button } from "@appkit4/react-components/button";
import { Checkbox } from "@appkit4/react-components/checkbox";
import _ from "lodash";
import { createColumnHelper } from "@tanstack/react-table";
import { Category } from "../../../../types/Category";
import { CustomColumn } from "../../../../components/Table/types";
import styles from "../Category.module.scss";

const columnHelper = createColumnHelper<Category>();

export const createCategoryColumns = (
  onEdit: (row: Category) => void,
  onDelete: (row: Category) => void
) => [
  {
    id: CustomColumn.DRAGGABLE,
    size: 24,
    cell: () => <span className="Appkit4-icon icon-menu-outline"></span>,
  },
  columnHelper.accessor((row) => row.name, {
    id: "name",
    header: () => "Name",
    meta: {
      hasTooltip: true,
    },
  }),
  columnHelper.accessor("description", {
    id: "description",
    header: () => "Description",
    meta: {
      hasTooltip: true,
    },
  }),
  columnHelper.accessor("isActive", {
    id: "isActive",
    header: () => "Active",
    size: 100,
    cell: (data) => <Checkbox className={styles.cursor} checked={data.cell.getValue()} />,
  }),
  columnHelper.accessor("id", {
    header: () => <span>Actions</span>,
    size: 100,
    cell: (row) => (
      <div className="ap-flex">
        <Button
          add
          kind="text"
          icon="icon-edit-outline"
          className="ap-text-neutral-10"
          onClick={() => onEdit(row.row.original)}
        />
        <Button
          add
          kind="text"
          icon="icon-delete-outline"
          className="ap-text-neutral-10"
          onClick={() => onDelete(row.row.original)}
        />
      </div>
    ),
  }),
];
