import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  pointIds: Yup.array()
    .min(1, "You have to select at least one guidance point")
    .required(),
});

export default validationSchema;
