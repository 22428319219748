import styles from "./PageHeader.module.scss";

type Props = {
  title: string;
  actions?: React.ReactNode;
};

const PageHeader = ({ title, actions }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className="ap-typography-heading-s">{title}</div>
      {actions}
    </div>
  );
};

export default PageHeader;
