import { ValueFormatterParams } from "ag-grid-enterprise";
import { format } from "date-fns/format";

export function dateFormatter(
  params: ValueFormatterParams,
  dateFormat?: string
): string {
  dateFormat = dateFormat ?? "MM/dd/yyyy hh:mma";
  return format(new Date(params.value), dateFormat);
}
