import { Checkbox as AppkitCheckbox } from "@appkit4/react-components";
import { FieldProps } from "../../types";

export default function Checkbox({ value, onUpdateCellValue }: FieldProps) {
  return (
    <AppkitCheckbox
      checked={value as boolean}
      onChange={(value) => onUpdateCellValue(value)}
    />
  );
}
