import { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import { Setting, SettingType } from "./types/Setting";
import { Button, Input, Switch } from "@appkit4/react-components";
import { Api } from "../../../api/apiHelper";
import { errorToast, successToast } from "../../../components/Toast";
import { appSettings } from "../../../api/endpoints/admin";
import PageSpinner from "../../../components/PageSpinner";
import styles from "./AppSettings.module.scss";
import clsx from "clsx";

const ApplicationSettings = () => {
  const [settings, setSettings] = useState<Setting[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Api.get(appSettings)
      .then((res) => {
        setSettings(res);
      })
      .catch(() => {
        errorToast({ message: "Failed to load settings" });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const configureSettingComponent = (setting: Setting) => {
    switch (setting.type) {
      case SettingType.Boolean:
        return (
          <Switch
            checked={setting.value as boolean}
            onChange={(val) => onSettingChange(setting, val)}
          />
        );
      case SettingType.String:
        return <Input value={setting.value as string} />;
      default:
        return <div>Not supported type</div>;
    }
  };

  const onSettingChange = (setting: Setting, value: boolean) => {
    setting.value = value;
    setSettings([...settings]);
  };

  const onSave = () => {
    Api.post(appSettings, settings)
      .then(() => {
        successToast({ message: "Settings have been saved successfully." });
      })
      .catch(() => {
        errorToast({ message: "Failed to save settings." });
      });
  };

  if (isLoading) return <PageSpinner />;

  return (
    <div className={clsx("full-height", styles.container)}>
      <PageHeader title="Application Settings" />

      <div className={styles.settingsContainer}>
        {settings.map((setting) => (
          <div key={setting.id} className={clsx("ap-flex", styles.settingItem)}>
            <label>{setting.name}</label>
            <div>{configureSettingComponent(setting)}</div>
          </div>
        ))}
      </div>

      <Button className={styles.saveBtn} onClick={onSave}>Save</Button>
    </div>
  );
};

export default ApplicationSettings;
