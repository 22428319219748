export const extractFileNameFromHeader = (response: Response) => {
    const content = response.headers.get('content-disposition') || "";

    const fileName = (content
          .split(';')
          .find(r => r.includes('filename=')) || "")
          .replace('filename=', '')
          .trim();

    return fileName.replace(/^"(.+)"$/,'$1');
}