import { useNavigate } from "react-router-dom";
import {
  Breadcrumb as BreadcrumbAppKit,
  BreadcrumbItem,
} from "@appkit4/react-components";

type Props = {
  list: {
    name: string;
    path?: string;
  }[];
  className?: string;
};

export const Breadcrumb = ({ list, className }: Props) => {
  const navigate = useNavigate();

  const navigateToRoute = (route?: string) => {
    if (!route) return;

    navigate(route);
  };

  return (
    <BreadcrumbAppKit className={className}>
      {list.map((item, idx) => (
        <BreadcrumbItem key={idx} onClick={() => navigateToRoute(item.path)}>
          <span>{item.name}</span>
        </BreadcrumbItem>
      ))}
    </BreadcrumbAppKit>
  );
};
