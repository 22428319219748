import PageHeader from "../../../components/PageHeader";
import { adminSections } from "../constants/adminSections";
import Card from "./components/Card";
import styles from "./Home.module.scss";

const Admin = () => {
  return (
    <div className={styles.wrapper}>
      <PageHeader title="Admin Home" />
      <div className={styles.sections}>
        {adminSections.map((section) => (
          <Card key={section.id} {...section} />
        ))}
      </div>
    </div>
  );
};

export default Admin;
