import { Option } from "../../../../types/Dropdown";
import { User, UserRow } from "../../../../types/User";

export const replaceUser =
  (newItem: User & { id: string }, roles: Option[]) => (item: UserRow) => {
    return item.id === newItem.id
      ? {
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          name: item.name,
          roles: roles.filter((role) => newItem.roleIds.includes(role.value)),
          isAdmin: item.isAdmin,
          pwCPPI: item.pwCPPI,
          pwcGuid: item.pwcGuid,
          email: item.email,
        }
      : item;
  };
