import { Button } from "@appkit4/react-components";
import clsx from "clsx";
import styles from "./AccordionItemHeader.module.scss";

type Props = {
  title: string;
  icon: "edit" | "save";
  onClick: () => void;
  isDisabled: boolean;
  onDelete: () => void;
};

export const AccordionItemHeader = ({
  title,
  icon,
  onClick,
  onDelete,
  isDisabled,
}: Props) => {
  return (
    <div className="ap-full-width ap-flex ap-justify-content-space-between">
      <span>{title}</span>
      <div className={clsx("ap-flex", styles.actions)}>
        <Button
          kind="text"
          icon={`icon-${icon}-outline`}
          className={clsx("ap-button-icon-only", styles.iconButton, {
            [styles.pulse]: icon === "save",
            [styles.primary]: icon === "save",
          })}
          onClick={onClick}
          disabled={isDisabled}
        />
        <Button
          add
          kind="text"
          icon={`icon-delete-outline`}
          className={clsx("ap-text-neutral-10", styles.iconButton)}
          onClick={onDelete}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};
