import React, { useContext, useState, useRef } from "react";
import ConfirmationModal from "../components/ConfirmationModal";

type ModalContextType = {
  showConfirmation: (
    title: string,
    message: string | JSX.Element,
    confirmButtonText?: string,
    cancelButtonText?: string
  ) => Promise<boolean>;
};

type ConfirmationModalContextProviderProps = {
  children: React.ReactNode;
};

const ConfirmationModalContext = React.createContext<ModalContextType>(
  {} as ModalContextType
);

const ConfirmationModalContextProvider: React.FC<
  ConfirmationModalContextProviderProps
> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [content, setContent] = useState<{
    title: string;
    message: string | JSX.Element;
    confirmButtonText?: string;
    cancelButtonText?: string;
  } | null>();
  const resolver = useRef<Function>();

  const showConfirmation = (
    title: string,
    message: string | JSX.Element,
    confirmButtonText?: string,
    cancelButtonText?: string
  ): Promise<boolean> => {    
    setContent({ title, message, confirmButtonText, cancelButtonText });
    setIsVisible(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const modalContext: ModalContextType = {
    showConfirmation,
  };

  const confirm = () => {
    resolver.current && resolver.current(true);
    setIsVisible(false);
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setIsVisible(false);
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}

      {content && (
        <ConfirmationModal
          isVisible={isVisible}
          title={content.title}
          message={content.message}
          confirmButtonText={content.confirmButtonText}
          cancelButtonText={content.cancelButtonText}
          onCancel={handleCancel}
          onConfirm={confirm}
        />
      )}
    </ConfirmationModalContext.Provider>
  );
};

export const useConfirmationModalContext = (): ModalContextType =>
  useContext(ConfirmationModalContext);

export default ConfirmationModalContextProvider;
