import { Button, Input, Modal, Upload } from "@appkit4/react-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Api } from "../../../../../api/apiHelper";
import { errorToast, successToast } from "../../../../../components/Toast";
import { formTest } from "../../../../../api/endpoints/forms";
import { messages } from "../../../../../constants/messages";
import styles from "../Modal.module.scss";

type SendForTestForm = {
  email: string;
  file?: File;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  file: Yup.mixed().required(),
});

type Props = {
  activeFormId?: string;
  onClose: () => void;
};

export const SendForTest = ({ onClose, activeFormId }: Props) => {
  const form = useFormik<SendForTestForm>({
    initialValues: { email: "", file: undefined },
    validationSchema,
    onSubmit: (values) => {
      if (!activeFormId || !values.file) return;

      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("email", values.email);
      Api.postFile(formTest(activeFormId), formData)
        .then(() => successToast({ message: "Test email has been send" }))
        .catch((err) =>
          errorToast({ message: `Failed to send test email. ${err}` })
        )
        .finally(() => {
          onClose();
        });
    },
  });

  const selectFile = (file: File) => {
    form.setFieldValue("file", file);
  };

  return (
    <Modal
      title="Send for test"
      onCancel={onClose}
      visible={true}
      initialFocus={false}
      maskCloseable={false}
      className={styles.modal}
      footer={
        <>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!form.isValid}
            loading={form.isSubmitting}
            onClick={form.handleSubmit}
          >
            Send For Test
          </Button>
        </>
      }
    >
      <div>
        <Input
          type="email"
          title="Email"
          value={form.values.email}
          onChange={(value: string) => form.setFieldValue("email", value)}
        />
        <Upload
          className={styles.upload}
          onChange={(file) => selectFile(file.originFile)}
          acceptFileType={".pdf"}
          uploadInstruction={messages.upload.instruction}
        />
      </div>
    </Modal>
  );
};
