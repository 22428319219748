import { DndProvider } from "react-dnd";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  Row,
  ColumnDefBase,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { HTML5Backend } from "react-dnd-html5-backend";
import clsx from "clsx";
import { DraggableTableRow } from "./components/DraggableRow";
import { useOutsideClickHandler } from "../../hooks/useOutsideClick";
import { BasicRowProps, CustomColumnMeta } from "./types";
import styles from "./Table.module.scss";
import {
  CONDENSED_ROW_HEIGHT,
  DEFAULT_COLUMN_WIDTH,
  DEFAULT_ROW_HEIGHT,
  MIN_HEADER_HEIGHT,
  TABLE_PADDING,
} from "./constants";

type TableProps<T> = {
  data: T[];
  columns: ColumnDef<T, any>[];
  onRowReordering?: (data: T[]) => void;
  onCellEdit?: (row: Row<T>, columnId: string, value: string) => void;
  striped?: boolean;
  condensed?: boolean;
  maxHeight?: number;
  isVirtualized?: boolean;
};

type ColumnMeta<T> = ColumnDefBase<T, string> & CustomColumnMeta;

const sortingIcons = {
  asc: <span className="Appkit4-icon icon-sorting-ascendant-fill"></span>,
  desc: <span className="Appkit4-icon icon-sorting-descendant-fill"></span>,
};

export default function Table<T extends BasicRowProps>({
  data,
  columns,
  onRowReordering,
  onCellEdit,
  striped,
  condensed,
  maxHeight,
  isVirtualized,
}: TableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const tableConfig = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData: onCellEdit,
    },
  });

  const [editingRowId, setEditingRowId] = useState<string | null>(null);
  const [tableHeight, setTableHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(MIN_HEADER_HEIGHT);

  const rowHeight = condensed ? CONDENSED_ROW_HEIGHT : DEFAULT_ROW_HEIGHT;

  const { rows } = tableConfig.getRowModel();

  const parentRef = useRef<HTMLDivElement>(null);

  useOutsideClickHandler(parentRef, () => setEditingRowId(null));

  const tableHeaderRef = useCallback((node) => {
    if (!node) return;
    const nodeHeight = node.getBoundingClientRect().height;
    nodeHeight > MIN_HEADER_HEIGHT && setHeaderHeight(nodeHeight);
  }, []);

  useLayoutEffect(() => {
    const actualHeight =
      (data.length ? data.length * rowHeight : 0) +
      headerHeight +
      TABLE_PADDING;

    setTableHeight(
      !maxHeight || actualHeight < maxHeight ? actualHeight : maxHeight
    );
  }, [data, maxHeight, rowHeight, headerHeight]);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => rowHeight,
    overscan: 20,
  });

  const items = isVirtualized
    ? virtualizer.getVirtualItems()
    : tableConfig.getRowModel().rows;

  const updateEditingRowId = (id: string) => {
    if (editingRowId === id) return;
    setEditingRowId(id);
  };

  const reorderRow = (draggedRowIndex: number, targetRowIndex: number) => {
    const updated = [...data];
    updated.splice(targetRowIndex, 0, updated.splice(draggedRowIndex, 1)[0]);

    onRowReordering && onRowReordering(updated);
  };

  return (
      <DndProvider backend={HTML5Backend}>
        <div className={styles.wrapper}>
          <div
            ref={parentRef}
            style={isVirtualized ? { height: `${tableHeight}px` } : undefined}
            className={clsx("ap-table", styles.container, {
              "ap-table-striped": striped,
              "ap-table-condensed": condensed,
            })}
          >
            <table>
              <thead ref={tableHeaderRef}>
                {tableConfig.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        style={{
                          width:
                            header.getSize() !== DEFAULT_COLUMN_WIDTH
                              ? header.getSize()
                              : undefined,
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            onClick={header.column.getToggleSortingHandler()}
                            className={clsx(
                              styles.headerCell,
                              styles[
                                (header.column.columnDef?.meta as ColumnMeta<T>)
                                  ?.className || ""
                              ]
                            )}
                          >
                            <span>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {sortingIcons[
                                header.column.getIsSorted() as string
                              ] ?? null}
                            </span>
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className={clsx({
                  [styles.striped]: striped,
                })}
              >
                {items.map((item, index) => {
                  const row = isVirtualized
                    ? (rows[item.index] as Row<T>)
                    : item;
                  return (
                    <DraggableTableRow<T>
                      row={row}
                      virtualRow={isVirtualized ? item : undefined}
                      key={row.id}
                      isActive={row.id === editingRowId}
                      setActiveRowId={() => updateEditingRowId(row.id)}
                      reorderRow={reorderRow}
                      index={index}
                      isVirtualized={isVirtualized}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </DndProvider>
  );
}
