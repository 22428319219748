import _ from "lodash";
import { createColumnHelper } from "@tanstack/react-table";
import { UserRow } from "../../../../types/User";
import { Option } from "../../../../types/Dropdown";
import { EditableCellType } from "../../../../components/Table/types";

const columnHelper = createColumnHelper<UserRow>();

export const createUserColumns = (userRoles: Option[]) => [
  columnHelper.accessor("firstName", {
    id: "firstName",
    header: () => "First Name",
  }),
  columnHelper.accessor("lastName", {
    id: "lastName",
    header: () => "Last Name",
  }),
  columnHelper.accessor("pwCPPI", {
    id: "pwCPPI",
    header: () => "PPID",
  }),
  columnHelper.accessor("email", {
    id: "email",
    header: () => "Email",
  }),
  columnHelper.accessor("roles", {
    id: "roles",
    header: () => "Roles",
    size: 320,
    cell: (data) =>
      data
        .getValue()
        .map((item) => item.label)
        .join(", "),
    meta: {
      isEditable: true,
      type: EditableCellType.MULTI_SELECT,
      options: userRoles,
    },
  }),
];
