import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { SdkPubSub } from "@pwc-sdk/spa-utils";
import Admin from "../pages/Admin/Home";
import UserContextProvider from "../contexts/UserContext";
import IUserProps from "../types/IUserProps";
import Home from "./Home";
import AppHeader from "../components/AppHeader";
import Categories from "../pages/Admin/Categories";
import EditorNotes from "../pages/Admin/EditorNotes";
import { AdminLayout } from "../components/AdminLayout";
import { AppKitToastContainer } from "../components/Toast";
import ConfirmationModalContext from "../contexts/ConfirmationModalContext";
import { Templates } from "../pages/Admin/Templates";
import Users from "../pages/Admin/Users";
import { Forms } from "../pages/Admin/Forms";
import { EditForm } from "../pages/Admin/Forms/EditForm";
import AppContextProvider from "../contexts/AppContext";
import Guidances from "../pages/Admin/Guidances";
import TemplateForm from "../pages/Admin/Templates/components/TemplateForm";
import TemplateContextProvider from "../contexts/TemplateContext";
import { ROUTES } from "../constants/routes";
import { DocBotTemplates } from "../pages/Admin/DocBotTemplates";
import { HOST_APP_BASE_URL } from "../constants/hostAppBaseUrl";
import ApplicationSettings from "../pages/Admin/AppSettings";
import { PublicClientApplication } from "@azure/msal-browser";
import { TRACK_MFE_CONFIG_NAME } from "../constants/trackMfeConfigName";
import { MfeRoutesHandler } from "./routeHandlers/MfeRoutesHandler";
import { TrackDocumentsRouteHandler } from "./routeHandlers/TrackDocumentsRouteHandler";

const sdkPubSub = new SdkPubSub();
sdkPubSub.register("C4T Props change", "c4t-track-props");

interface IContentProps {
  user?: IUserProps;
  msalInstance?: PublicClientApplication;
}

const Content = ({ user, msalInstance }: IContentProps) => {
  return (
    <UserContextProvider
      user={user}
      key={user?.email}
      msalInstance={msalInstance}
    >
      <AppContextProvider>
        <ConfirmationModalContext>
          <TemplateContextProvider>
            <div className="d-flex flex-column full-height">
              <AppHeader />
              <div className="content-wrapper ap-bg-color-background-default ap-position-relative">
                <Routes>
                  <Route
                    key={TRACK_MFE_CONFIG_NAME}
                    path={`${HOST_APP_BASE_URL}/*`}
                    element={<MfeRoutesHandler />}
                  />

                  <Route
                    key="track"
                    path="/"
                    element={<TrackDocumentsRouteHandler />}
                  />

                  <Route
                    key="admin"
                    path="admin"
                    element={<AdminLayout user={user} />}
                  >
                    <Route index element={<Admin />} />
                    <Route path={ROUTES.USERS.path} element={<Users />} />
                    <Route
                      path={ROUTES.CATEGORIES.path}
                      element={<Categories />}
                    />
                    <Route
                      path={ROUTES.GUIDANCES.path}
                      element={<Guidances />}
                    />
                    <Route
                      path={ROUTES.EDITOR_NOTES.path}
                      element={<EditorNotes />}
                    />
                    <Route path={ROUTES.TEMPLATES.path}>
                      <Route index element={<Templates />} />
                      <Route path=":templateId" element={<TemplateForm />} />
                      <Route
                        path={ROUTES.CREATE_TEMPLATE.path}
                        element={<TemplateForm />}
                      />
                    </Route>
                    <Route path={ROUTES.FORMS.path}>
                      <Route index element={<Forms />} />
                      <Route path="new" element={<EditForm />} />
                      <Route path=":formId" element={<EditForm />} />
                    </Route>

                    <Route
                      path={ROUTES.DOC_BOT_TEMPLATES.path}
                      element={<DocBotTemplates />}
                    />
                    <Route
                      path={ROUTES.SETTINGS.path}
                      element={<ApplicationSettings />}
                    />
                  </Route>

                  <Route path="home" element={<Home />} />
                </Routes>
              </div>

              <AppKitToastContainer />
            </div>
          </TemplateContextProvider>
        </ConfirmationModalContext>
      </AppContextProvider>
    </UserContextProvider>
  );
};

export default Content;
