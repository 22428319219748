import { Entity } from "../../../../types/Entity";
import { FormType } from "../../../../types/FormType";
import { TagDataType } from "../../../../types/TagDataType";

export const createFormTag = (role: Entity) => ({
  roleId: role.id,
  roleName: role.name,
  coordinates: [
    {
      x: 0,
      y: 0,
      type: TagDataType.SIGNATURE,
      pageNumber: 1,
    },
    {
      x: 0,
      y: 0,
      type: TagDataType.DATE,
      pageNumber: 1,
    },
  ],
});

export const createNewForm = (roles: Entity[]) => ({
  name: "",
  type: FormType.Individual,
  isActive: true,
  formTags: roles.map(createFormTag),
});
