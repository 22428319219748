import styles from "./EngagementRenderer.module.scss";
import { ICellRendererParams } from 'ag-grid-enterprise';

type Props = {
  onNavigate: (id: string) => void;
} & ICellRendererParams;

export default function EngagementRenderer({ onNavigate, ...cellParams }: Props) {
  return (
    <span className={styles.text} onClick={() => onNavigate(cellParams.data.id)}>
      {!!cellParams.valueFormatted ? cellParams.valueFormatted : cellParams.value}
    </span>
  );
}
