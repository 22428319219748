import { Button, Checkbox } from "@appkit4/react-components";
import { Form } from "../types/Form";

export const createColumns = (
  onActiveChange: (value: boolean, id: string) => void,
  onTest: (id: string) => void,
  onEdit: (id: string) => void,
  onDelete: (id: string) => void
) => {
  return [
    {
      field: "type",
      text: "Form Group Name",
    },
    {
      field: "name",
      text: "Form Name",
    },
    {
      field: "isActive",
      text: "Active",
      renderCell: (row: Form) => (
        <Checkbox
          checked={row.isActive}
          onChange={(val) => onActiveChange(val, row.id)}
        />
      ),
    },
    {
      field: "id",
      text: "Actions",
      slot: "start",
      renderCell: (row: Form) => (
        <div className="ap-flex ap-align-items-center ap-justify-content-end">
          <Button
            onClick={() => onTest(row.id)}
            kind="text"
            icon="icon-email-outline"
            className="ap-button-icon-only"
          />

          <Button
            onClick={() => onEdit(row.id)}
            kind="text"
            icon="icon-edit-outline"
            className="ap-button-icon-only"
          />

          <Button
            onClick={() => onDelete(row.id)}
            kind="text"
            icon="icon-delete-outline"
            className="ap-button-icon-only"
          />
        </div>
      ),
    },
  ];
};
