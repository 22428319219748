import { Tooltip } from "@appkit4/react-components";
import { ICellRendererParams } from "ag-grid-enterprise";

export default function CheckMarkRenderer(cellParams: ICellRendererParams) {

  if (cellParams.value === true)
    return (<Tooltip
      position="top-left"
      id={cellParams.data.id}
      distance={4}
      content="Document has been successfully uploaded to Tax Docs"
    >
      <span className="Appkit4-icon icon-check-mark-fill ap-text-neutral-10"></span>
    </Tooltip>);
  else
    return (<></>);
}
