import { useCallback } from "react";
import { debounce } from "lodash";
import {
  DEFAULT_SEARCH_DEBOUNCE_MS,
  MIN_SEARCH_LENGTH,
} from "../constants/appConstants";
import { Api } from "../api/apiHelper";
import { formatUserToDropdownOptions } from "../helpers/formatUserToDropdownOption";
import { LdapUser } from "../types/User";
import { UserDropdownOption } from "../types/Dropdown";
import { getLdapUsers } from "../api/endpoints/users";

export const useLoadUserOptions = <T extends { email: string }>(users: T[]) => {
  const _loadUserOptions = useCallback(
    (searchTerm: string, callback: (data: UserDropdownOption[]) => void) => {
      if (searchTerm.length < MIN_SEARCH_LENGTH) return;

      Api.get(getLdapUsers(searchTerm))
        .then((data: LdapUser[]) =>
          data.filter(
            (item) => !users.map((user) => user.email).includes(item.email)
          )
        )
        .then((res) => res.map(formatUserToDropdownOptions))
        .then((filtered) => callback(filtered));
    },
    [users]
  );

  const loadUserOptions = debounce(
    _loadUserOptions,
    DEFAULT_SEARCH_DEBOUNCE_MS
  );

  return { loadUserOptions };
};
