import { useMemo } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Button } from "@appkit4/react-components/button";
import { SlideOut } from "../../../components/SlideOut";
import { DocumentStatus, IDocument } from "../../../types/Document";
import { useConfirmationModalContext } from "../../../contexts/ConfirmationModalContext";
import { Api } from "../../../api/apiHelper";
import { deactivateDocument } from "../../../api/endpoints/documents";
import createDetails from "./createDetails";
import styles from "./TrackDetails.module.scss";
import { HOST_APP_BASE_URL } from "../../../constants/hostAppBaseUrl";
import { getNavigationLink } from "../helpers/getNavigationLink";
import MFE_ROUTES from "../../../constants/mfeRoutes";

type Props = {
  id: string | null;
  rows: IDocument[];
  close: () => void;
  handleDocumentRemove: (status: DocumentStatus) => void;
};

export default function TrackDetails({
  id,
  rows,
  close,
  handleDocumentRemove,
}: Props) {
  const confirmationModal = useConfirmationModalContext();
  const navigate = useNavigate();

  const document = rows.find((row) => row.id === id);

  const isDocumentDeleteDisabled = document?.status == DocumentStatus.Completed;

  const details = useMemo(() => {
    return !!document ? createDetails(document) : [];
  }, [id, document]);

  const openDocument = (): void => {
    if (document?.status === DocumentStatus.Draft) {
      navigate(
        `${HOST_APP_BASE_URL}${getNavigationLink(document)}?documentId=${
          document.id
        }`
      );
    } else {
      navigate(`${HOST_APP_BASE_URL}${MFE_ROUTES.DETAILS}?documentId=${document?.id}`);
    }
  };

  const deleteDocument = () => {
    if (!id) return;
    confirmationModal
      .showConfirmation(
        "Confirmation",
        "Are you sure you want to delete the document?"
      )
      .then((isConfirmed: boolean) => {
        isConfirmed &&
          Api.delete(deactivateDocument(id)).then(() => {
            const removedItem = _.find(rows, (r) => r.id == id);
            removedItem && handleDocumentRemove(removedItem.status);
            close();
          });
      });
  };

  return (
    <SlideOut
      autoFocus={false}
      visible={!!id}
      onCancel={close}
      title="Document Details"
      bodyStyle={{
        position: "relative",
      }}
      footer={
        <Button
          kind="tertiary"
          icon="icon-delete-outline"
          compact
          onClick={deleteDocument}
          disabled={isDocumentDeleteDisabled}
        >
          Delete Document
        </Button>
      }
    >
      <>
        <Button
          kind="text"
          icon="icon-open-in-new-outline"
          compact
          onClick={openDocument}
          className={styles.open}
        >
          Open Document
        </Button>
        {details.map(({ id, items }, index) => (
          <div key={id}>
            {items.map(({ id, name, value }) => (
              <div key={id}>
                <div className={styles.label}>{name}</div>
                <div className={styles.value}>{value}</div>
              </div>
            ))}
            {index !== details.length - 1 && <div className={styles.divider} />}
          </div>
        ))}
      </>
    </SlideOut>
  );
}
