import { DocumentSigner } from "./Signer";

export enum DocumentType {
  DocBot = "DocBot",
  Individual = "Individual",
  Entity = "Entity",
  Custom = "Custom",
}

export enum DocumentStatus {
  Draft = "Draft",
  InReview = "InReview",
  AwaitingClientSignature = "AwaitingClientSignature",
  AwaitingPwCSignature = "AwaitingPwCSignature",
  Completed = "Completed",
  Expired = "Expired",
  AwaitingPaperSignature = "AwaitingPaperSignature",
}

export interface BaseDocument {
  type: DocumentType;
  status: DocumentStatus;
  engagementId: string;
  createdBy: string;
  createdDate: string;
  subTemplateId: string;
  subTemplateName: string;
  templateName: string;
}

export interface IDocument extends BaseDocument {
  id: string;
  engagementName: string;
  engagementIsStandalone: boolean;
  entityName: string;
  modifiedBy: string;
  modifiedDate: string;
  pwCSignerName: string;
  docuSignEmailSubject: string;
  isPublished: boolean;
  docBotCode: string;
  signers?: DocumentSigner[];
  uploadedToTaxDocs?: boolean;
}

export interface DocumentDetails extends BaseDocument {
  currentVersionId: string;
  documentRecordCode: string;
  uploadDocumentsAllowed: boolean;
  currentWizardStep: string;
  lastFilledSection: string;
  entityName: string;
  isEngagementCenter: boolean;
  // tagsEditingType: TagsEditingType;
  signerSearchFilterName?: string;
  // contactSettings?: ContactPageSettings;
  hasPwcSigner?: boolean;
  hasPwcContact?: boolean;
  // letterLocation: LetterLocation;
  isRedliningAllowed: boolean;
  engagementName: string;
  clientName: string;
}

export type DocumentVersion = {
  id: string;
  version: number;
  createdDate: string;
  userEmailId: string;
  userRoles: string;
  note: string;
  createdBy: string;
  redliningReasons: string;
  isCurrent: boolean;
  // redliningMethod: RedliningMethod;
  documentFormat: string[];
};

