import { Button, Modal } from "@appkit4/react-components";
import styles from "./ConfirmationModal.module.scss";

type Props = {
  isVisible: boolean;
  title: string;
  message: string | JSX.Element;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmationModal = ({
  isVisible,
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  onCancel,
  onConfirm,
}: Props) => {
  return (
    <Modal
      visible={isVisible}
      className={styles.modal}
      title={title}
      closable
      onCancel={onCancel}
      footer={
        <>
          <Button kind="secondary" onClick={onCancel}>
            {cancelButtonText ?? "Cancel"}
          </Button>
          <Button kind="negative" onClick={onConfirm}>
            {confirmButtonText ?? "Confirm"}
          </Button>
        </>
      }
    >
      <div className={styles.message}>{message}</div>
    </Modal>
  );
};

export default ConfirmationModal;
