import { Option } from "../../../../types/Dropdown";
import Select from "../../../Select";
import { FieldProps } from "../../types";
import styles from "./EditableCell.module.scss";

export const MultiSelect = ({
  value,
  onChange,
  options,
  onUpdateCellValue,
}: FieldProps) => {
  return (
    <Select
      options={options}
      value={value}
      isMulti
      onChange={(value) => onChange(value as Option[])}
      onBlur={() => onUpdateCellValue()}
      menuPortalTarget={document.body}
      className={styles.multiSelect}
    />
  );
};
