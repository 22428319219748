/* eslint-disable max-lines */
/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import Parcel from 'single-spa-react/parcel';
import { mountRootParcel } from 'single-spa';
import { Loading } from '@appkit4/react-components';

const consoleLogger = { error: (err, config) => console.log('SpaContainer Error:', { err, config }) };
/**
 * React specific container component to help fetch and render a single-spa parcel.
 */
export class SpaContainer extends React.Component {
  /**
   * Initializes SpaContainer state with provided props
   * @param {element} appendTo optional arg, allowing anchoring of parcel to custom element
   * @param {object} guestConfig core configuration object for the guest
   * @param {object} logger optional logger provided from host, allowing us to better track exceptions
   * @param {element} onErrorComponent optional component to be shown if an error occurs when fetching/mounting the parcel
   * @param {func} onErrorHandler optional function callback when a lifecycle error occurs.
   * @param {element} onProgressComponent optional component to show while a parcel/assets are being fetched over the network
   * @param {object} parcelConfig optional object with valid parcel interface. Used for loading locally resolved/loaded parcels.
   */
  constructor({
    appendTo,
    guestConfig,
    logger = consoleLogger,
    onErrorComponent,
    onErrorHandler,
    onProgressComponent,
    parcelConfig
  }) {
    if (!guestConfig) {
      throw new Error('Invalid Argument: guestConfig is required');
    }
    super();

    let isIframeParcel = false;
    if (this.getParcelIframeInformation(guestConfig)) {
      isIframeParcel = true;
    }
    this.state = {
      name: this.getParcelName(guestConfig),
      teamName: this.getTeamName(guestConfig),
      appendTo: appendTo || null,
      basePath: guestConfig.path || null,
      logger: logger || null,
      parcelConfig: parcelConfig || null, //already resolved/loaded parcel
      isLoaded: 'undefined',
      isIframeParcel: isIframeParcel,
      messageReceived: false
    };
    let that = this;
    if (isIframeParcel) {
      var eventhandler = function (e) {
        if (e.data.success) {
          that.setState({
            name: that.state.name,
            teamName: that.teamName,
            appendTo: that.state.appendTo,
            basePath: that.state.basePath,
            logger: that.state.logger,
            parcelConfig: that.state.parcelConfig,
            isLoaded: 'true',
            isIframeParcel: that.state.isIframeParcel,
            messageReceived: true
          });
        } else {
          that.setState({
            name: that.state.name,
            teamName: that.state.teamName,
            appendTo: that.state.appendTo,
            basePath: that.state.basePath,
            logger: that.state.logger,
            parcelConfig: that.state.parcelConfig,
            isLoaded: 'false',
            isIframeParcel: that.state.isIframeParcel,
            messageReceived: true
          });
        }
      };

      window.addEventListener('message', eventhandler, false);

      setTimeout(() => {
        window.removeEventListener('message', eventhandler, false);
        if (!this.state.messageReceived) {
          this.setState({
            name: this.state.name,
            teamName: this.state.teamName,
            appendTo: this.state.appendTo,
            basePath: this.state.basePath,
            logger: this.state.logger,
            parcelConfig: this.state.parcelConfig,
            isLoaded: 'false',
            isIframeParcel: this.state.isIframeParcel
          });
        }
      }, 20000);
    }
  }

  /**
   * Updates the component state with raised error details
   * @param {*} error
   */
  static getDerivedStateFromError(error) {
    return { error: error };
  }

  /**
   * Fetches parcel config from a remote source
   * @param {*} source The source configuration object for remote parcels
   */
  async _fetchRemoteParcel(source, manifest = null) {
    //Build parcel uri.
    let parcelUri = `${source.root}/${source.src}`; //default path

    //try and use manifest if provided
    if (manifest) {
      let fileName = source.src;

      //check if src is a path. Grab file name if so
      if (fileName.indexOf('/') > -1) {
        let pathTokens = fileName.split('/');
        fileName = pathTokens[pathTokens.length - 1];
      }

      if (((manifest || {}).files || {})[fileName]) {
        //override path to parcel with entry in manifest
        parcelUri = manifest.files[fileName];
      }
    }

    //fetch remote parcel module
    //this either returns a cached version or fetches a new file if not already in cache.
    return window.System.import(parcelUri);
  }

  /**
   * Given the provided config, tries to fetch the configured manifest file. The manifest is always checked before load.
   * If it hasn't changed we get the cached broser version, otherwise we get a new one. The new manifest will trigger the request for the updated parcel.
   * @param {*} source
   */
  async _fetchRemoteManifest(source) {
    let manifestUri = `${source.root}/${source.manifest}`;
    let manifest = null;
    //try fetch manifest
    try {
      const manifestResponse = await fetch(manifestUri);

      if (manifestResponse.ok) {
        manifest = await manifestResponse.json();
      } else {
        throw new Error(`GET ${manifestUri} ${manifestResponse.status}`);
      }
    } catch (error) {
      this.onError(error);
    }

    return manifest;
  }

  /**
   * Attempts to load configured parcel module
   */
  async componentDidMount() {
    //if parcel is alread loaded(provided as optional prop), skip remote resolve
    if (this.state.parcelConfig) return;
    try {
      let guestConfig = this.props.guestConfig;
      let source = guestConfig.source;
      let parcelConfig = null;
      let manifest = null;

      let iframeConfig = { height: source.height, width: source.width };

      //determine where to get the parcel, remote/local
      if (source.manifest) {
        manifest = await this._fetchRemoteManifest(source);
        parcelConfig = await this._fetchRemoteParcel(source, manifest);
      } else {
        parcelConfig = await this._fetchRemoteParcel(source);
      }

      //unable to load a parcel for this config
      if (!parcelConfig) {
        throw new Error('Unable to load guest parcel configuration');
      }

      //mount parcelConfig, re-render.
      this.setState({
        parcelConfig: parcelConfig
      });
    } catch (error) {
      //handle parcel load errors
      if (this.state.logger && this.state.logger.error) {
        this.state.logger.error(error, this.props.guestConfig);
      }
      this.setState({ error: error });
    }
  }

  /**
   * Create an error boundry to prevent unhandled exceptions from bubling up further
   * @param {*} error
   */
  componentDidCatch(error) {
    this.onError(error);
  }

  /**
   * Renders the configured parcel component. Alternately, will show error/progress component if provided.
   */
  render() {
    if (this.state.error) {
      //error registered after trying to load
      return this.props.onErrorComponent || null;
    }

    if (!this.state.parcelConfig) {
      //still loading
      return this.props.onProgressComponent || null;
    }
    //we have a parcel, attempt to render it
    //short term, passing all props to guest. TODO: remove catch all ...this.props

    if (this.state.isIframeParcel) {
      return (
        <div className={this.props.className} data-testid={this.props.guestConfig.name}>
          <div
            class="embed-responsive-item"
            style={{
              visibility: this.state.isLoaded === 'undefined' ? 'visible' : 'hidden',
              display: this.state.isLoaded === 'false' || this.state.isLoaded === 'true' ? 'none' : 'block'
            }}>
            <Loading loadingType="circular" circularWidth="24px" />
          </div>
          <div
            style={{
              visibility: this.state.isLoaded === 'true' ? 'visible' : 'hidden',
              display: this.state.isLoaded === 'false' ? 'none' : 'block'
            }}>
            <Parcel
              {...this.props}
              config={{ ...this.state.parcelConfig, name: this.state.name }}
              mountParcel={mountRootParcel}
              appendTo={this.state.appendTo}
              handleError={this.onError}
            />
          </div>
          <div
            class="embed-responsive-item"
            style={{
              visibility: this.state.isLoaded === 'false' ? 'visible' : 'hidden',
              display: this.state.isLoaded === 'false' || this.state.isLoaded === 'undefined' ? 'block' : 'none',
              textAlign: 'center'
            }}>
            <p>This is an integrated app. For support, please log a ticket with the {this.state.teamName} team.</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className={this.props.className} data-testid={this.props.guestConfig.name}>
          <Parcel
            {...this.props}
            config={{ ...this.state.parcelConfig, name: this.state.name }}
            mountParcel={mountRootParcel}
            appendTo={this.state.appendTo}
            handleError={this.onError}
          />
        </div>
      );
    }
  }

  /**
   * Logs parcel errors
   */
  onError = error => {
    if (this.props.onErrorHandler) {
      this.props.onErrorHandler(error);
    }
    if (this.state.logger && this.state.logger.error) {
      this.state.logger.error(error);
    }
  };

  /**
   * Creates a unique parcel name
   */
  getParcelName = guestConfig => {
    return `${guestConfig.name}_${Math.floor(Math.random(1000000000) * 1000000000)}`;
  };

  getParcelIframeInformation = guestConfig => {
    return guestConfig.source.iframe && guestConfig.source.postback;
  };

  getTeamName = guestConfig => {
    return `${guestConfig.name}`;
  };
}

SpaContainer.propTypes = {
  appendTo: PropTypes.instanceOf(Element), //optional arg, allowing anchoring of parcel to custom element
  className: PropTypes.string, //optional styles to add to root div
  guestConfig: PropTypes.shape({
    //core configuration object for the guest
    name: PropTypes.string.isRequired, //name of the parcel
    path: PropTypes.string, //optional path to be used by a parcel to declare child routes
    source: PropTypes.shape({
      //map with information on where to load parce + assets
      iframe: PropTypes.string, // path of site to load with standard framework iframe parcel
      manifest: PropTypes.string, //path to asset manifest. Used to map clean file names like 'parcel.js' to hashed values like 'parcel.xxxxxx.js'
      root: PropTypes.string, //the root URL from where to resolve
      resource: PropTypes.string, //relative path to localized resources
      src: PropTypes.string, //name of the main parcel file. (usually parcel.js)
      height: PropTypes.string, // height
      width: PropTypes.string // width
    }),
    context: PropTypes.string //context string indicating the intent for this parcel
  }),
  logger: PropTypes.shape({
    //optional logger provided from host, allowing us to better track exceptions
    critical: PropTypes.func,
    error: PropTypes.func,
    info: PropTypes.func,
    warn: PropTypes.func,
    verbose: PropTypes.func
  }),
  onErrorComponent: PropTypes.element, //optional component to be shown if an error occurs when fetching/mounting the parcel
  onErrorHandler: PropTypes.func,
  onProgressComponent: PropTypes.element, //optional component to show while a parcel/assets are being fetched over the network
  parcelConfig: PropTypes.shape({
    //allow for local parcel loading
    bootstrap: PropTypes.func,
    mount: PropTypes.func,
    unmount: PropTypes.func
  })
};
