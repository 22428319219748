import { toast, ToastContainer, Slide } from "react-toastify";
import { Notice } from "@appkit4/react-components/notification";

export enum StatusType {
  Success = "success",
  Warning = "warning",
  Error = "error",
}

type ToastProps = {
  title?: string;
  message?: string;
};

type Props = {
  message: string;
  status: StatusType;
  title: string;
  setShow: (show: boolean) => void;
};

export default function AppKitToast({
  status,
  title,
  message,
  setShow,
}: Props) {
  return (
    <Notice
      title={title}
      message={message}
      status={status}
      onClose={() => setShow(false)}
    />
  );
}

const showToast = (status: StatusType, title: string, message = "") => {
  toast(
    ({ closeToast }: any) => (
      <AppKitToast
        status={status}
        title={title}
        message={message}
        setShow={closeToast}
      />
    ),
    { progressClassName: status, hideProgressBar: true }
  );
};

const successToast = ({ title = "Success", message }: ToastProps) =>
  showToast(StatusType.Success, title, message);
const warningToast = ({ title = "Warning", message }: ToastProps) =>
  showToast(StatusType.Warning, title, message);
const errorToast = ({ title = "Error", message }: ToastProps) =>
  showToast(StatusType.Error, title, message);

const AppKitToastContainer = () => (
  <ToastContainer
    autoClose={3000}
    pauseOnHover
    closeButton={false}
    transition={Slide}
  />
);

export { AppKitToastContainer, successToast, warningToast, errorToast };
