import MFE_ROUTES from "../../../constants/mfeRoutes";
import { BaseDocument, DocumentStatus, DocumentType } from "../../../types/Document";

export const getNavigationLink = ({ status, type }: BaseDocument) => {
  switch (status) {
    case DocumentStatus.InReview:
      return MFE_ROUTES.REVIEW;
    case DocumentStatus.AwaitingClientSignature:
    case DocumentStatus.AwaitingPwCSignature:
    case DocumentStatus.Completed:
    case DocumentStatus.AwaitingPaperSignature:
      return MFE_ROUTES.DETAILS;
    default: {
      switch (type) {
        case DocumentType.DocBot:
          return MFE_ROUTES.CREATE;
        case DocumentType.Individual:
          return MFE_ROUTES.INDIVIDUALS_WIZARD;
        case DocumentType.Entity:
          return MFE_ROUTES.ENTITIES_WIZARD;
        case DocumentType.Custom:
          return MFE_ROUTES.CUSTOM_DOCUMENT;
      }
    }
  }
};
