import { useEffect, useMemo, useState } from "react";
import { Button } from "@appkit4/react-components";
import { Api } from "../../../api/apiHelper";
import { Category, NewCategory } from "../../../types/Category";
import Table from "../../../components/Table";
import EditCategoryModal from "./components/EditCategoryModal";
import {
  deleteCategory as deleteCategoryApi,
  categories as categoriesApi,
  allCategories,
} from "../../../api/endpoints/categories";
import PageHeader from "../../../components/PageHeader";
import { replaceCategory } from "./helpers/replaceCategory";
import { createCategoryColumns } from "./helpers/createCategoryColumns";
import { createNewCategory } from "./helpers/createNewRow";
import { CategoryPayload } from "./types/CategoryPayload";
import { isExistingCategory } from "./types/typeGuards";
import { errorToast, successToast } from "../../../components/Toast";
import { useConfirmationModalContext } from "../../../contexts/ConfirmationModalContext";
import PageSpinner from "../../../components/PageSpinner";

const Categories = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [activeCategory, setActiveCategory] = useState<
    Category | NewCategory | null
  >(null);

  const modalContext = useConfirmationModalContext();

  useEffect(() => {
    setIsLoadingCategories(true);
    Api.get(allCategories)
      .then((res) => {
        setCategories(res);
      })
      .catch(() => {
        errorToast({ message: "Failed to load categories" });
      })
      .finally(() => setIsLoadingCategories(false));
  }, []);

  const openEditCategoryModal = (row: Category) => {
    setActiveCategory(row);
  };

  const confirmCategoryModalChanges = (data: CategoryPayload) => {
    if (isExistingCategory(activeCategory)) {
     return Api.put(categoriesApi, data)
        .then(() => {
          setCategories(categories.map(replaceCategory(data)));
          closeModal();
          successToast({ message: "Category has been updated" });
        })
        .catch(() => errorToast({ message: "Failed to update the category" }));
    } else {
     return Api.post(categoriesApi, data)
        .then((res) => JSON.stringify(res))
        .then((id) => {
          setCategories((prev) => [{ ...data, id }, ...prev]);
          closeModal();
          successToast({ message: "Category has been created" });
        })
        .catch(() => errorToast({ message: "Failed to create the category" }));
    }
  };

  const deleteCategory = (row: Category) => {
    if (!row.id) return;
    modalContext
      .showConfirmation(
        "Delete category",
        "Are you sure you want to delete this category with all subCategories?"
      )
      .then((isConfirmed) => {
        if (!isConfirmed) return;
        Api.post(deleteCategoryApi(row.id), {})
          .then(() => {
            setCategories((prev) => prev.filter((item) => item.id !== row.id));
            successToast({ message: "Category has been deleted" });
          })
          .catch(() =>
            errorToast({ message: "Failed to delete the category" })
          );
      });
  };

  const closeModal = () => {
    setActiveCategory(null);
  };

  const reorderCategories = (reordered: Category[]) => {
    setCategories(reordered);
  };

  const addNewCategory = () => {
    setActiveCategory(createNewCategory());
  };

  const columns = useMemo(
    () => createCategoryColumns(openEditCategoryModal, deleteCategory),
    []
  );

  if (isLoadingCategories) return <PageSpinner />;

  return (
    <div>
      <PageHeader
        title="Categories & SubCategories"
        actions={
          <Button kind="secondary" onClick={addNewCategory}>
            Add Category
          </Button>
        }
      />
      <Table
        data={categories}
        columns={columns}
        onRowReordering={reorderCategories}
        striped
        condensed
      />

      {activeCategory && (
        <EditCategoryModal
          category={activeCategory}
          onClose={closeModal}
          onConfirm={confirmCategoryModalChanges}
        />
      )}
    </div>
  );
};

export default Categories;
