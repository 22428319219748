import { ICellRendererParams } from "ag-grid-enterprise";
import { Badge } from "@appkit4/react-components";
import { getStatusColor } from "../../../../helpers/getColor";
import { DOCUMENT_STATUS_NAMES } from "../../../../constants/documentStatusName";

export default function StatusRenderer(cellParams: ICellRendererParams) {
  const badgeColor = getStatusColor(cellParams.value);

  return (
    <Badge
      value={DOCUMENT_STATUS_NAMES[cellParams.value]}
      style={{
        color: badgeColor,
        backgroundColor: "transparent",
        boxShadow: `0 0 0 0.0625rem ${badgeColor}`,
      }}
      type="primary-outlined"
    />
  );
}
