import { CategoryPayload } from "../types/CategoryPayload";
import { Category } from "../../../../types/Category";
import { isExistingCategory } from "../types/typeGuards";

export const replaceCategory =
  (newItem: CategoryPayload) => (item: Category) => {
    return isExistingCategory(newItem) && item.id === newItem.id
      ? {
          id: newItem.id,
          order: newItem.order,
          name: newItem.name,
          description: newItem.description,
          isActive: newItem.isActive,
        }
      : item;
  };
