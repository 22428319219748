import { useEffect, useState } from "react";
import { Api } from "../../../api/apiHelper";
import PageHeader from "../../../components/PageHeader";
import { Button, Tab, Tabs } from "@appkit4/react-components";
import Table from "../../../components/Table";
import { getTableColumns } from "./getTableColumns";
import {
  guidances as guidancesEndpoint,
  deleteGuidance as deleteGuidanceApi,
} from "../../../api/endpoints/guidances";
import { Guidance } from "../../../types/Guidance";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder";
import ManageGuidancePoints from "./components/ManageGuidancePoints/ManageGuidancePoints";
import styles from "./Guidances.module.scss";
import { useConfirmationModalContext } from "../../../contexts/ConfirmationModalContext";
import GuidanceSlideOut from "./components/EditGuidance/GuidanceSlideOut";
import GuidanceModal from "./components/CreateGuidance/GuidanceModal";

const Guidances = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [guidances, setGuidances] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedGuidance, setSelectedGuidance] = useState<Guidance | null>(
    null
  );

  const [isAddGuidanceModalVisible, setIsAddGuidanceModalVisible] =
    useState(false);

  const confirmationModal = useConfirmationModalContext();

  const onTabChange = (i: number, value: string) => {
    setActiveTab(i);
  };

  useEffect(() => {
    loadGuidances();
  }, []);

  const loadGuidances = () => {
    setIsLoading(true);
    Api.get(guidancesEndpoint)
      .then((res) => {
        setGuidances(res);
      })
      .finally(() => setIsLoading(false));
  };

  const onEdit = (guidance: Guidance) => {
    setSelectedGuidance(guidance);
  };

  const onDelete = (guidanceId: string) => {
    setIsLoading(true);
    confirmationModal
      .showConfirmation(
        "Confirmation",
        "Are you sure you want to delete guidance?"
      )
      .then((isConfirmed: boolean) => {
        isConfirmed && deleteGuidance(guidanceId, false);
      });
  };

  const deleteGuidance = (guidanceId: string, force: boolean) => {
    Api.delete(deleteGuidanceApi(guidanceId))
      .then((res) => {
        loadGuidances();
      })
      .catch((ex) => {
        ex.response.json().then((ex) => {
          confirmationModal
            .showConfirmation("Are you sure you want to proceed?", ex.errors)
            .then((isConfirmed: boolean) => {
              isConfirmed && deleteGuidance(guidanceId, true);
            });
        });
      })
      .finally(() => setIsLoading(false));
  };

  const onEditGuidance = (pointIds: string[]) => {
    setIsAddGuidanceModalVisible(true);
    setSelectedGuidance({
      ...selectedGuidance,
      pointIds,
    } as Guidance);
  };

  const onSaveGuidance = (guidance: Guidance) => {
    setIsAddGuidanceModalVisible(false);
    loadGuidances();

    if (!!guidance.id) {
      setSelectedGuidance({ ...guidance });
    }
  };

  const tableColumns = getTableColumns(onEdit, onDelete);

  return (
    <>
      <PageHeader title="Manage Guidances" />

      <div className={styles.container}>
        <Tabs type="filled" activeIndex={activeTab} onTabChange={onTabChange}>
          <Tab label="Guidances" value="Guidances"></Tab>
          <Tab label="Guidance Points" value="Guidance Points"></Tab>
        </Tabs>

        {activeTab == 0 && (
          <>
            {isLoading && <LoadingPlaceholder overlay="parent" />}

            <div className={styles.guidanceContainer}>
              <Button
                kind="secondary"
                className={styles.addButton}
                onClick={() => setIsAddGuidanceModalVisible(true)}
              >
                Add New
              </Button>

              <Table data={guidances} columns={tableColumns} striped />
            </div>

            {selectedGuidance && (
              <GuidanceSlideOut
                guidance={selectedGuidance}
                onEdit={onEditGuidance}
                onCancel={() => setSelectedGuidance(null)}
              />
            )}

            <GuidanceModal
              onSave={onSaveGuidance}
              guidance={selectedGuidance}
              onCancel={() => setIsAddGuidanceModalVisible(false)}
              isVisible={isAddGuidanceModalVisible}
            />
          </>
        )}
        {activeTab == 1 && <ManageGuidancePoints />}
      </div>
    </>
  );
};

export default Guidances;
