import { Button, Input, Modal } from "@appkit4/react-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DocBotTemplate, NewDocBotTemplate } from "../../../../../types/Template";
import styles from "./DocBotTemplateForm.module.scss";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  docBotId: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must contain only numeric characters")
    .min(4, "Must have at least 4 digits")
    .max(5, "Must have up to 5 digits"),
});

type Props = {
  activeTemplate: DocBotTemplate | NewDocBotTemplate;
  onSubmit: (values: DocBotTemplate | NewDocBotTemplate) => void;
  onClose: () => void;
};

export const DocBotTemplateForm = ({
  onClose,
  activeTemplate,
  onSubmit,
}: Props) => {
  const form = useFormik<DocBotTemplate | NewDocBotTemplate>({
    initialValues: activeTemplate,
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <Modal
      title="Edit DocBot Template"
      onCancel={onClose}
      visible={true}
      initialFocus={false}
      maskCloseable={false}
      className={styles.modal}
      footer={
        <>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={form.isSubmitting} onClick={form.handleSubmit}>
            Save
          </Button>
        </>
      }
    >
      <Input
        title="Name"
        value={form.values.name}
        onChange={(value: string) => form.setFieldValue("name", value)}
        className="ap-mb-spacing-4"
        error={form.touched.name && !!form.errors.name}
        errorNode={form.errors.name}
      />
      <Input
        title="DocBot Id"
        value={form.values.docBotId}
        onChange={(value: string) => form.setFieldValue("docBotId", value)}
        error={form.touched.docBotId && !!form.errors.docBotId}
        errorNode={form.errors.docBotId}
      />
    </Modal>
  );
};
