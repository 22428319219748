export const formsFilter = `/api/forms/filter`;

export const forms = `/api/forms`;

export const formById = (formId: string) => `/api/forms/${formId}`;

export const formTest = (formId: string) => `/api/forms/${formId}/test`;

export const formMarkTags = (formId: string) =>
  `/api/forms/${formId}/mark-tags`;
