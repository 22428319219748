import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
  isActive: Yup.boolean().required(),
  subCategories: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
        description: Yup.string().required(),
      })
    )
    .required()
    .min(1),
});
