import clsx from "clsx";
import { Header } from "@appkit4/react-components";
import NavMenu from "../NavMenu";
import { UserTemplate } from "./UserTemplate";
import styles from "./AppHeader.module.scss";

const AppHeader = () => {
  const Title = () => <span className="cursor-pointer">Create For Tax</span>;
  const Options = () => <NavMenu />;

  return (
    <Header
      hasLogo={true}
      titleTemplate={Title}
      type="solid"
      userTemplate={UserTemplate}
      contentTemplate={Options}
      className={clsx(styles["c4t-header"], "ap-level-2")}
    />
  );
};

export default AppHeader;
