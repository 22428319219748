import { useRef } from "react";
import { Column, Row, Table } from "@tanstack/react-table";
import { Tooltip } from "@appkit4/react-components";
import clsx from "clsx";
import { useOverflow } from "../../../../hooks/useOverflow";
import styles from "../../Table.module.scss";

type Props<T> = {
  getValue: () => unknown;
  row: Row<T>;
  column: Column<T>;
  table: Table<T>;
};

export const TooltipCell = <T extends object>({
  getValue,
  row,
  column,
}: Props<T>) => {
  const cellValue = getValue() as string;
  const tooltipId = `cell-${row.id}-${column.id.split(" ").join("-")}`;

  const cellRef = useRef<HTMLDivElement>(null);
  const { isHorizontalOverflow } = useOverflow(cellRef);

  return (
    <div
      ref={cellRef}
      className={clsx(tooltipId, styles.truncate)}
      style={{ width: "100%" }}
    >
      <Tooltip
        id={tooltipId}
        target={`.${tooltipId}`}
        trigger="hover"
        position="top-left"
        distance={4}
        content={cellValue}
        disabled={!isHorizontalOverflow}
      />
      {cellValue}
    </div>
  );
};
