import { DocumentStatus } from "../../../types/Document";

export const STATUS_FILTER_OPTIONS = [
  DocumentStatus.Draft,
  DocumentStatus.InReview,
  DocumentStatus.AwaitingClientSignature,
  DocumentStatus.AwaitingPwCSignature,
  DocumentStatus.Completed,
  DocumentStatus.Expired,
  DocumentStatus.AwaitingPaperSignature,
];
