import {
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  Modal,
} from "@appkit4/react-components";
import PageHeader from "../../../components/PageHeader";
import {
  deleteEditorNote,
  editorNotes as editorNotesEndpoint,
} from "../../../api/endpoints/editorNotes";
import { Api } from "../../../api/apiHelper";
import { useEffect, useState } from "react";
import Table from "../../../components/Table";
import { TextEditor } from "@appkit4/react-text-editor";
import "@appkit4/react-text-editor/dist/appkit4-react-texteditor.min.css";
import styles from "../EditorNotes/EditorNotes.module.scss";
import { toolBarSettings } from "./helpers/toolBarSettings";
import { modalBodyFullHeight, textEditorStyle } from "./helpers/inlineStyles";
import { EditorNote, SubTemplateModel } from "./helpers/types";
import { getTableColumns } from "./helpers/getTableColumns";
import { useConfirmationModalContext } from "../../../contexts/ConfirmationModalContext";

const emptyNote = { id: "", title: "", message: "" };

const EditorNotes = () => {
  const [editorNotes, setEditorNotes] = useState<EditorNote[]>([]);
  const [currentNote, setCurrentNote] = useState<EditorNote | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkModalVisible, setIsLinkModalVisible] = useState(false);
  const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);
  const [connectedSubTemplates, setConnectedSubTemplates] = useState<
    SubTemplateModel[]
  >([]);

  const modalContext = useConfirmationModalContext();

  useEffect(() => {
    loadEditorNotes();
  }, []);

  const saveNote = () => {
    setIsLoading(true);
    const endpoint = currentNote?.id ? Api.put : Api.post;

    endpoint(editorNotesEndpoint, currentNote).then(() => {
      setCurrentNote(null);
      setIsLoading(false);
      setIsAddEditModalVisible(false);
      loadEditorNotes();
    });
  };

  const deleteNote = (noteId: string, force?: boolean) => {
    Api.delete(deleteEditorNote(noteId, !!force))
      .then(() => {
        setIsLoading(false);
        loadEditorNotes();
      })
      .catch((ex) => {
        ex.response.json().then((ex) => {
          modalContext
            .showConfirmation("Are you sure you want to proceed?", ex.errors)
            .then(
              (isConfirmed: boolean) => isConfirmed && deleteNote(noteId, true)
            );
        });
      });
  };

  const editNote = (note: EditorNote) => {
    setCurrentNote(note);
    setIsAddEditModalVisible(true);
  };

  const loadEditorNotes = () => {
    Api.get(editorNotesEndpoint).then((res: EditorNote[]) => {
      setEditorNotes(res);
    });
  };

  const tableColumns = getTableColumns(editNote, deleteNote);

  // const loadSubTemplates = () => {
  //   Api.get("/api/sub-templates").then((res: SubTemplateModel[]) => {
  //     setConnectedSubTemplates(res);
  //   });
  // };

  return (
    <>
      <div>
        <PageHeader
          title="Editor Notes"
          actions={
            <Button
              kind="secondary"
              onClick={() => {
                setCurrentNote(emptyNote);
                setIsAddEditModalVisible(true);
              }}
            >
              Add Editor Note
            </Button>
          }
        />

        <Table data={editorNotes} columns={tableColumns} striped />
      </div>

      <Modal
        visible={isAddEditModalVisible}
        onCancel={() => {
          setCurrentNote(null);
          setIsAddEditModalVisible(false);
        }}
        className={styles.modal}
        title={currentNote?.id ? "Edit Note" : "Add New Note"}
        bodyStyle={modalBodyFullHeight}
        footer={
          <>
            <Button
              kind="secondary"
              onClick={() => {
                setCurrentNote(null);
                setIsAddEditModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={saveNote}>Save</Button>
          </>
        }
      >
        <div className={styles.editorContainer}>
          <Input
            value={currentNote?.title}
            onChange={(val: string) =>
              currentNote && setCurrentNote({ ...currentNote, title: val })
            }
          ></Input>
          <TextEditor
            onChange={(event, editor, data) =>
              currentNote && setCurrentNote({ ...currentNote, message: data })
            }
            data={currentNote ? currentNote?.message : ""}
            config={{
              toolbar: toolBarSettings,
            }}
            style={textEditorStyle}
          ></TextEditor>
        </div>
      </Modal>

      <Modal
        visible={isLinkModalVisible}
        title={currentNote?.title}
        onCancel={() => {
          setCurrentNote(null);
          setIsLinkModalVisible(false);
        }}
        footer={
          <>
            <Button
              kind="secondary"
              onClick={() => {
                setCurrentNote(null);
                setIsLinkModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={() => setIsLinkModalVisible(false)}>Save</Button>
          </>
        }
      >
        <p className={styles.linkedHeader}>Linked SubTemplates: </p>
        <CheckboxGroup
          className="ap-mb-spacing-3"
          //onChange={setCheckedConditions}
          //value={checkedConditions}
        >
          {connectedSubTemplates.map(({ templateName, name }, id) => (
            <Checkbox
              key={templateName + id}
              value={name}
              className="ap-mb-spacing-3"
            >
              <div>
                {templateName} - {name}
              </div>
            </Checkbox>
          ))}
        </CheckboxGroup>

        {connectedSubTemplates.map((st) => (
          <Checkbox checked={st.editorNotesId === currentNote?.id}></Checkbox>
        ))}
      </Modal>
    </>
  );
};

export default EditorNotes;
