import clsx from "clsx";
import { ICellRendererParams } from "ag-grid-enterprise";
import "./ActionsRenderer.module.scss";
import { DropdownButton } from "@appkit4/react-components";
import { TableActions } from "../../../../types/TableActions";
import {
  ItemDataType,
  ValueType,
} from "@appkit4/react-components/esm/dropdown/DropdownButton";
import { DocumentStatus, IDocument } from "../../../../types/Document";

const iconsMap = {
  [TableActions.Open]: "icon-open-in-new-outline",
  [TableActions.GetLink]: "icon-copy-outline",
  [TableActions.Delete]: "icon-delete-outline",
};

interface IActionParams extends ICellRendererParams {
  onDeleteDocument: (id: string, status: DocumentStatus) => void;
  onGetDocumentLink: (id: string) => void;
  onNavigateToDocument: (document: IDocument) => void;
}

export default function ActionsRenderer({
  onGetDocumentLink,
  onNavigateToDocument,
  onDeleteDocument,
  ...cellParams
}: IActionParams) {
  const documentId = cellParams.value;
  const documentStatus = cellParams.data?.status as DocumentStatus;

  const actions: ItemDataType[] = [
    { value: TableActions.Open, label: "Open Document" },
    { value: TableActions.GetLink, label: "Copy Link to Document" },
  ];

  if (documentStatus === DocumentStatus.Draft)
    actions.push({ value: TableActions.Delete, label: "Delete Document" });

  const handleOnSelect = (value: ValueType) => {
    switch (value) {
      case TableActions.Open:
        onNavigateToDocument(cellParams.data);
        return;
      case TableActions.GetLink:
        onGetDocumentLink(documentId);
        return;
      case TableActions.Delete:
        onDeleteDocument(documentId, documentStatus);
        return;
    }
  };

  return (
    <DropdownButton
      kind="text"
      data={actions}
      compact
      onSelect={handleOnSelect}
      itemTemplate={(label, item) => (
        <>
          {label}
          <span
            //@ts-ignore
            className={clsx("Appkit4-icon", iconsMap[item.value])}
          ></span>
        </>
      )}
    >
      <span className="Appkit4-icon icon-vertical-more-outline"></span>
    </DropdownButton>
  );
}
