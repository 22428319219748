import { Link } from "react-router-dom";
import { NoResults } from "../../../../components/NoResults";
import NoDocumentsIcon from "../../../../assets/NoDocuments.svg";
import styles from "./NoDocuments.module.scss";
import MFE_ROUTES from "../../../../constants/mfeRoutes";
import { HOST_APP_BASE_URL } from "../../../../constants/hostAppBaseUrl";

export default function NoDocuments() {
  return (
    <div className="ap-pt-spacing-8">
      <NoResults
        icon={NoDocumentsIcon}
        mainMessage="No Documents Found"
        additionalMessage={
          <div className="ap-typography-body">
            <p>There are no documents to display for this client. </p>
            <span>Select a different client, or </span>
            <Link to={`${HOST_APP_BASE_URL}${MFE_ROUTES.ENGAGEMENT}`} className={styles.action}>
              create a new document.
            </Link>
          </div>
        }
      />
    </div>
  );
}
