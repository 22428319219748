import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styles from "./PDFViewer.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();



type Props = {
  file: React.ComponentProps<typeof Document>["file"];
  scale?: number;
  onDocumentLoad?: () => void;
};

export default function PDFViewer({ file, scale, onDocumentLoad }: Props) {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    onDocumentLoad && onDocumentLoad();
  };

  return (
    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          className={styles.page}
          scale={scale || 1.5}
        />
      ))}
    </Document>
  );
}
