import styles from "./EditableCell.module.scss";
import { FieldProps } from "../../types";

export default function Input({ value, onUpdateCellValue, onChange, autoFocus }: FieldProps) {
  return (
    <input
      className={styles.input}
      value={value as string}
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => onUpdateCellValue()}
      autoFocus={autoFocus}
    />
  );
}
