import { ColDef, ISetFilterParams, SideBarDef } from 'ag-grid-enterprise';

export const getDefaultColDef = () =>
  ({
    resizable: true,
    minWidth: 140,
    pinned: null,
    flex: 1,
    filter: 'agSetColumnFilter',
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    filterParams: {
      buttons: ['reset'],
      closeOnApply: true,
      defaultToNothingSelected: true,
    } as ISetFilterParams,
  } as ColDef);


  export const getSidebar = (): SideBarDef => ({
    toolPanels: [
      // {
      //   id: 'columns',
      //   labelDefault: 'Columns',
      //   labelKey: 'columns',
      //   iconKey: 'columns',
      //   toolPanel: 'agColumnsToolPanel',
      //   toolPanelParams: {
      //     suppressRowGroups: true,
      //     suppressValues: true,
      //     suppressPivots: true,
      //     suppressPivotMode: true,
      //     suppressColumnFilter: true,
      //     suppressColumnSelectAll: true,
      //     suppressColumnExpandAll: true,
      //   },
      // },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
        },
      },
    ],
  });
