import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  type: Yup.string().required(),
  formTags: Yup.array().of(
    Yup.object().shape({
      roleName: Yup.string().required(),
      coordinates: Yup.array().of(
        Yup.object().shape({
          x: Yup.number().min(1).max(1000).required(),
          y: Yup.number().min(1).max(1000).required(),
          type: Yup.string().required(),
          pageNumber: Yup.number().required().min(1),
        })
      ),
    })
  ),
});
