import { DocumentStatus } from "../../../types/Document";
import { DOCUMENT_TILE_ALL, DOCUMENT_TILE_SIGNATURES } from "../types";
import {AWAITING_SIGNATURE_STATUSES } from './configureStatusFilterParams';

export const getSelectedTile = (statuses: string[]) => {
  if (!statuses?.length) {
    return DOCUMENT_TILE_ALL;
  }

  if (
    statuses.every((s) =>
      AWAITING_SIGNATURE_STATUSES.includes(s as DocumentStatus)
    )
  ) {
    return DOCUMENT_TILE_SIGNATURES;
  }

  return statuses[0];
};
