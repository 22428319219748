import { components, OptionProps } from "react-select";
import { Avatar } from "@appkit4/react-components/avatar";
import clsx from "clsx";
import styles from "./UserOption.module.scss";
import { UserDropdownOption } from "../../../../types/Dropdown";
import { AVATAR_COLOR } from "../../../../constants/colors";

export default function UserOption(props: OptionProps) {
  const { label, details, avatarLabel } = props.data as UserDropdownOption;

  return (
    <components.Option {...props}>
      <div className={clsx("ap-flex", "ap-align-items-center")}>
        <Avatar label={avatarLabel} bgColor={AVATAR_COLOR} />
        <div className="ap-px-spacing-4">
          <div className={styles.label}>{label}</div>
          {details && <div className={styles.details}>{details}</div>}
        </div>
      </div>
    </components.Option>
  );
}
