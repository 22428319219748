import {
  Row,
  flexRender,
  ColumnMeta as TanstackColumnMeta,
} from "@tanstack/react-table";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { clsx } from "clsx";
import { VirtualItem } from "@tanstack/react-virtual";
import { CustomColumn, CustomColumnMeta } from "../../types";
import styles from "../../Table.module.scss";
import { EditableCell } from "../EditableCell";
import { onDraggableRowHover } from "../../helpers/onDraggableRowHover";
import { TooltipCell } from "../TooltipCell/TooltipCell";

type TableRow<T> = {
  row: Row<T>;
  virtualRow?: VirtualItem;
  isActive: boolean;
  setActiveRowId: () => void;
  reorderRow: (draggedRowIdx: number, rowIdx: number) => void;
  index: number;
  isVirtualized?: boolean;
};

type ColumnMeta<T> = TanstackColumnMeta<T, string> & CustomColumnMeta;

const noop = () => {};
const DRAG_TYPE = "row";

const style = (virtualRow: VirtualItem, index: number) => ({
  transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
  height: `${virtualRow?.size}px`,
});

export const DraggableTableRow = <T extends object>({
  row,
  reorderRow,
  virtualRow,
  isActive,
  setActiveRowId,
  index,
  isVirtualized,
}: TableRow<T>) => {
  const dropRef = useRef(null);

  const [, drop] = useDrop({
    accept: DRAG_TYPE,
    drop: isVirtualized
      ? (draggedRow: any) => reorderRow(draggedRow.index, row.index)
      : noop,
    hover: isVirtualized
      ? noop
      : onDraggableRowHover(dropRef, index, reorderRow),
  });

  const [{ isDragging }, dragRef, preview] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: DRAG_TYPE,
  });

  preview(drop(dropRef));

  return (
    <tr
      ref={dropRef}
      style={virtualRow && style(virtualRow, index)}
      onClick={setActiveRowId}
    >
      {row.getVisibleCells().map((cell) => {
        const cellWidth = cell.column.getSize();
        if (isDragging) {
          return (
            <td
              key={cell.id}
              className={clsx(styles.hidden)}
              style={{ maxWidth: `${cellWidth}px` }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        }
        if (cell.column.id === CustomColumn.DRAGGABLE) {
          return (
            <td ref={dragRef} key={cell.id}>
              <div className={styles.drag}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            </td>
          );
        } else {
          const { isEditable, hasTooltip } =
            (cell.column.columnDef.meta as ColumnMeta<T>) || {};

          const isEditableCell = isEditable && isActive;
          return (
            <td key={cell.id} style={{ maxWidth: `${cellWidth}px` }}>
              {flexRender(
                isEditableCell
                  ? EditableCell
                  : hasTooltip
                  ? TooltipCell
                  : cell.column.columnDef.cell,
                cell.getContext()
              )}
            </td>
          );
        }
      })}
    </tr>
  );
};
