import { ControlProps, components } from "react-select";
import clsx from "clsx";
import styles from "./CustomControl.module.scss";
import { CustomSelectProps } from "../../common";
import { messages } from "../../../../constants/messages";

type SelectProps = ControlProps["selectProps"] & CustomSelectProps;

export default function CustomControl(props: ControlProps) {
  const { isFocused, menuIsOpen, hasValue } = props;
  const { requiredStyles, title, error } = props.selectProps as SelectProps;
  const isInputFocused = (isFocused && menuIsOpen) || hasValue;

  return (
    <components.Control {...props}>
      <div
        className={clsx(
          styles.wrapper,
          isInputFocused && styles["label-focused"]
        )}
      >
        <span
          className={clsx(
            styles.label,
            requiredStyles && styles.required,
            error && styles.error
          )}
        >
          {title || messages.common.selectPlaceholder}
        </span>
      </div>
      {props.children}
    </components.Control>
  );
}
