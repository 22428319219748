import { MutableRefObject } from "react";

export const onDraggableRowHover =
  (
    dropRef: MutableRefObject<HTMLTableRowElement | null> ,
    index: number,
    reorderRow: (draggedRowIdx: number, rowIdx: number) => void
  ) =>
  (item: any, monitor: any) => {
    if (!dropRef?.current) {
      return;
    }
    const dragIndex = item.index;
    const hoverIndex = index;

    if (dragIndex === hoverIndex) {
      return;
    }
    // Determine rectangle on screen
    const hoverBoundingRect = dropRef.current.getBoundingClientRect();
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    // Determine mouse position
    const clientOffset = monitor.getClientOffset();
    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    reorderRow(dragIndex, hoverIndex);
    item.index = hoverIndex;
  };
