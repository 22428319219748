export const guidances = "/api/guidances";
export const guidancePoints = (guidanceId: string) =>
  `/api/guidances/${guidanceId}/points`;
export const deleteGuidance = (id: string) => `/api/guidances/${id}`;
export const deletePointFromGuidance = (id: string, pointId: string) =>
  `/api/guidances/${id}/points/${pointId}`;

export const getAllPoints = () => `/api/guidance-points`;
export const deleteGuidancePoint = (id: string, force: boolean) =>
  `/api/guidance-points/${id}?force=${force}`;
export const guidancePoint = (guidancePointId: string) =>
  `/api/guidance-points/${guidancePointId}`;
