import { Button } from "@appkit4/react-components";
import { GuidancePoint } from "../../../../../../types/Guidance";
import { createColumnHelper } from "@tanstack/react-table";
import { guidancePointDescriptionStyles, guidancePointTitleStyles } from "../../../helpers/inlineStyles";

const columnHelper = createColumnHelper<GuidancePoint>();

export const getTableColumns = (
  onEdit: (guidance: GuidancePoint) => void,
  onDelete: (noteId: string) => void
) => [
  {
    id: "title",
    size: 300,
    header: () => "Title",
    cell: (row) => (
      <div style={guidancePointTitleStyles}>{row.row.original.title}</div>
    ),
  },
  {
    id: "description",
    size: 500,
    header: () => "Description",
    cell: (row) => (
      <div
        style={guidancePointDescriptionStyles}
        dangerouslySetInnerHTML={{ __html: row.row.original.description }}
      />
    ),
  },
  columnHelper.accessor("id", {
    header: () => <span>Actions</span>,
    size: 100,
    cell: ({ row }) => (
      <div className="ap-flex">
        <Button
          add
          kind="text"
          icon="icon-edit-outline"
          className="ap-text-neutral-10"
          onClick={() => onEdit(row.original)}
        />
        <Button
          add
          kind="text"
          icon="icon-delete-outline"
          className="ap-text-neutral-10"
          onClick={() => onDelete(row.original.id)}
        />
      </div>
    ),
  }),
];
