export const toolBarSettings = [
  "fontFamily",
  "fontSize",
  "bold",
  "italic",
  "strikethrough",
  "underline",
  "bulletedList",
  "numberedList",
  "indent",
  "outdent",
  "alignment:left",
  "alignment:center",
  "alignment:right",
  "alignment:justify",
  "link",
  "undo",
  "redo",
];
