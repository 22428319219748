import {
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  Modal,
} from "@appkit4/react-components";
import { modalBodyFullHeight } from "../../../EditorNotes/helpers/inlineStyles";
import { useEffect, useState } from "react";
import { Guidance, GuidancePoint } from "../../../../../types/Guidance";
import styles from "../../Guidances.module.scss";
import { useFormik } from "formik";
import { Api } from "../../../../../api/apiHelper";
import {
  guidances,
  getAllPoints,
} from "../../../../../api/endpoints/guidances";
import validationSchema from "./helpers/validationSchema";
import clsx from "clsx";

type Props = {
  isVisible: boolean;
  guidance: Guidance | null;
  onCancel: () => void;
  onSave: (guidance: Guidance) => void;
};

const GuidanceModal = ({ onSave, onCancel, isVisible, guidance }: Props) => {
  const [guidancePoints, setGuidancePoints] = useState<GuidancePoint[]>([]);

  const form = useFormik<Guidance>({
    initialValues: { id: "", name: "", pointIds: [] },
    validationSchema,
    onSubmit: () => {
      saveForm();
    },
  });

  useEffect(() => {
    Api.get(getAllPoints()).then((res) => {
      setGuidancePoints(res);
    });
  }, []);

  useEffect(() => {
    form.setValues({
      id: guidance?.id || "",
      name: guidance?.name || "",
      pointIds: guidance?.pointIds || [],
    });
  }, [guidance]);

  const saveForm = () => {
    const endpoint = form.values.id ? Api.put : Api.post;

    endpoint(guidances, form.values).then(() => {
      form.resetForm();
      onSave(form.values);
    });
  };

  const closeForm = () => {
    form.resetForm();
    onCancel();
  };

  return (
    <Modal
      visible={isVisible}
      title={guidance?.id ? "Edit Guidance" : "Create Guidance"}
      bodyStyle={modalBodyFullHeight}
      wrapperStyle={{ zIndex: 3000 }}
      className={styles.modal}
      onCancel={closeForm}
      footer={
        <>
          <Button kind="secondary" onClick={closeForm}>
            Cancel
          </Button>
          <Button onClick={form.submitForm}>Save</Button>
        </>
      }
    >
      <>
        <Input
          value={form.values.name}
          title="Guidance Name"
          required
          onChange={(val: string) => form.setFieldValue("name", val)}
          error={form.touched.name && !!form.errors.name}
          errorNode={form.errors.name}
        />
        <div className={styles.editGuidanceContainer}>
          <h3>Select Guidance Points</h3>
          <CheckboxGroup
            className={clsx("ap-mb-spacing-3", styles.points)}
            onChange={(values) => form.setFieldValue("pointIds", values)}
            value={form.values.pointIds}
          >
            {guidancePoints.map(({ id, title }) => (
              <Checkbox key={id} value={id} className="ap-mb-spacing-3">
                {title}
              </Checkbox>
            ))}
          </CheckboxGroup>
          {form.touched.pointIds && !!form.errors.pointIds && (
            <div className="field-error">{form.errors.pointIds}</div>
          )}
        </div>
      </>
    </Modal>
  );
};

export default GuidanceModal;
