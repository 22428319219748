import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  Button,
  Column,
  Loading,
  Pagination,
  Search,
  Table,
} from "@appkit4/react-components";
import PageHeader from "../../../components/PageHeader";
import { Api } from "../../../api/apiHelper";
import { formById, formsFilter } from "../../../api/endpoints/forms";
import { errorToast, successToast } from "../../../components/Toast";
import { useConfirmationModalContext } from "../../../contexts/ConfirmationModalContext";
import { useDebouncedValue } from "../../../hooks/useDebouncedValue";
import { messages } from "../../../constants/messages";
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_SEARCH_DEBOUNCE_MS } from "../../../constants/appConstants";
import { SendForTest } from "./components/SendForTest";
import { Form } from "./types/Form";
import { createColumns } from "./helpers/createColumns";
import styles from "./Forms.module.scss";

type FormsRes = {
  items: Form[];
  offset: number;
  limit: number;
  total: number;
};

export const Forms = () => {
  const navigate = useNavigate();
  const { showConfirmation } = useConfirmationModalContext();

  const [forms, setForms] = useState<FormsRes>({
    items: [],
    offset: 0,
    limit: DEFAULT_ITEMS_PER_PAGE,
    total: 0,
  });
  const [isLoadingForms, setIsLoadingForms] = useState(false);
  const [activeFormId, setActiveFormId] = useState<string>();
  const [searchText, setSearchText] = useState("");

  const [queryParams, setQueryParams] = useState({
    offset: 0,
    search: "",
    limit: DEFAULT_ITEMS_PER_PAGE,
  });

  useEffect(() => {
    setIsLoadingForms(true);
    Api.get(formsFilter, queryParams)
      .then((data) => setForms(data))
      .catch((error) =>
        errorToast({ message: `Failed to load forms. ${error}` })
      )
      .finally(() => setIsLoadingForms(false));
  }, [queryParams]);

  const totalPages = useMemo(
    () => Math.ceil((forms?.total || 0) / queryParams.limit),
    [forms, queryParams]
  );

  const page = useMemo(
    () => queryParams.offset / queryParams.limit + 1,
    [queryParams]
  );

  const debouncedSearchText = useDebouncedValue(
    searchText,
    searchText ? DEFAULT_SEARCH_DEBOUNCE_MS : 0
  );

  useEffect(() => {
    debouncedSearchText !== queryParams.search &&
      setQueryParams({
        ...queryParams,
        search: debouncedSearchText,
        offset: 0,
      });
  }, [debouncedSearchText]);

  const updatePage = (page: number) =>
    setQueryParams({ ...queryParams, offset: (page - 1) * queryParams.limit });

  const onActiveChange = (value: boolean, id: string) => {
    Api.patch(formById(id), value.toString())
      .then(() => {
        successToast({ message: "Form has been updated" });
        setForms((prev) => ({
          ...prev,
          items: prev.items.map((form) =>
            form.id === id ? { ...form, isActive: value } : form
          ),
        }));
      })
      .catch((error) =>
        errorToast({ message: `Failed to update form. ${error}` })
      );
  };

  const onEdit = (id: string) => {
    navigate(`${id}`);
  };

  const onTest = (id: string) => {
    setActiveFormId(id);
  };

  const onDelete = (id: string) => {
    showConfirmation(
      "Delete Form",
      "Are you sure you want to delete this form?"
    ).then((isConfirmed) => {
      if (isConfirmed) {
        Api.delete(formById(id))
          .then(() => {
            successToast({ message: "Form has been deleted" });
            setForms((prev) => ({
              ...prev,
              items: prev.items.filter((form) => form.id !== id),
            }));
          })
          .catch((error) =>
            errorToast({ message: `Failed to delete form. ${error}` })
          );
      }
    });
  };

  const columns = useMemo(
    () => createColumns(onActiveChange, onTest, onEdit, onDelete),
    [onActiveChange]
  );
  return (
    <>
      <PageHeader
        title="Forms"
        actions={
          <div className="ap-flex ap-align-items-center ap-flex-gap-default">
            <Search
              searchType="secondary"
              placeholder={messages.common.searchPlaceholder}
              searchValue={searchText}
              onChange={setSearchText}
              onClear={() => setSearchText("")}
              className={styles.search}
            />
            <Button onClick={() => navigate("new")}>Create New</Button>
          </div>
        }
      />
      {isLoadingForms && !forms.items.length ? (
        <Loading loadingType="circular" indeterminate></Loading>
      ) : (
        <>
          <div
            className={clsx(styles.wrapper, isLoadingForms && styles.disabled)}
          >
            <Table originalData={forms.items} hasTitle striped condensed>
              {columns.map((column) => (
                <Column key={column.field} {...column}>
                  {column.text}
                </Column>
              ))}
            </Table>
          </div>

          <Pagination
            current={page}
            total={totalPages}
            onPageChange={updatePage}
          />
        </>
      )}

      {activeFormId && (
        <SendForTest
          onClose={() => {
            setActiveFormId(undefined);
          }}
          activeFormId={activeFormId}
        />
      )}
    </>
  );
};
