import { v4 as uuid } from "uuid";

export const createNewCategory = () => ({
  order: 0,
  name: "",
  description: "",
  isActive: true,
});

export const createNewSubCategory = (isActive: boolean) => ({
  id: uuid(),
  order: 0,
  name: "",
  description: "",
  isNew: true,
  isActive,
});
