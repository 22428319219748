import { Accordion, AccordionItem, Button } from "@appkit4/react-components";
import {
  deletePointFromGuidance,
  guidancePoints,
} from "../../../../../api/endpoints/guidances";
import { SlideOut } from "../../../../../components/SlideOut";
import { Guidance, GuidancePoint } from "../../../../../types/Guidance";
import LoadingPlaceholder from "../../../../../components/LoadingPlaceholder";
import { useEffect, useState } from "react";
import { Api } from "../../../../../api/apiHelper";
import styles from "../../Guidances.module.scss";
import EditGuidancePoint from "../ManageGuidancePoints/GuidancePointModal";
import { useConfirmationModalContext } from "../../../../../contexts/ConfirmationModalContext";

type Props = {
  guidance: Guidance;
  onCancel: () => void;
  onEdit: (selectedPoints: string[]) => void;
};

const GuidanceSlideOut = ({ guidance, onCancel, onEdit }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [points, setPoints] = useState<GuidancePoint[]>([]);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [currentPoint, setCurrentPoint] = useState<GuidancePoint | null>(null);

  const modalContext = useConfirmationModalContext();

  useEffect(() => {
    if (guidance?.id) loadGuidancePoints(guidance.id);
  }, [guidance]);

  const onClickAccordion = (activeKeys: string[]) => {
    setActiveKeys(activeKeys);
  };

  const editGuidancePoint = (point: GuidancePoint) => {
    setCurrentPoint(point);
  };

  const loadGuidancePoints = (guidanceId: string) => {
    setIsLoading(true);
    Api.get(guidancePoints(guidanceId))
      .then((res) => {
        setPoints(res);
      })
      .finally(() => setIsLoading(false));
  };

  const onPointDelete = (pointId: string) => {
    modalContext
      .showConfirmation(
        "Confirmation",
        "Are you sure you want to delete guidance point?"
      )
      .then((isConfirmed: boolean) => {
        isConfirmed &&
          Api.delete(deletePointFromGuidance(guidance.id, pointId)).then(() => {
            loadGuidancePoints(guidance.id);
          });
      });
  };

  const onEditGuidance = () => {
    onEdit(points.map((point) => point.id));
  }

  return (
    <>
      <SlideOut
        visible={!!guidance}
        onCancel={onCancel}
        title={guidance?.name}
        footer={
          <Button
            kind="secondary"
            onClick={onEditGuidance}
          >
            Edit
          </Button>
        }
      >
        <>
          {isLoading ? (
            <LoadingPlaceholder overlay="parent" />
          ) : (
            <Accordion
              multiple={true}
              onClick={onClickAccordion}
              activeKeys={activeKeys}
            >
              {points?.map((point: GuidancePoint, index: number) => (
                <AccordionItem
                  key={index}
                  itemKey={point.id}
                  title={point.title}
                >
                  <div className={styles.guidanceItem}>
                    <div
                      dangerouslySetInnerHTML={{ __html: point.description }}
                    ></div>

                    <div className={styles.actions}>
                      <Button
                        add
                        kind="text"
                        icon="icon-edit-outline"
                        className="ap-text-neutral-10"
                        onClick={() => editGuidancePoint(point)}
                      ></Button>
                      <Button
                        add
                        kind="text"
                        icon="icon-delete-outline"
                        className="ap-text-neutral-10"
                        onClick={() => onPointDelete(point.id)}
                      ></Button>
                    </div>
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </>
      </SlideOut>

      <EditGuidancePoint
        currentPoint={currentPoint}
        onCancel={() => setCurrentPoint(null)}
        onSave={() => {
          setCurrentPoint(null);
          loadGuidancePoints(guidance.id);
        }}
      />
    </>
  );
};

export default GuidanceSlideOut;
