import apiConfig from "../api/apiConfig";
import { getQueryParamsStr } from "../api/apiHelper";
import { successToast } from "../components/Toast";
import { copyToClipboard } from "./copyToClipboard"

export default function copyDocumentLinkToClipboard(documentId: string){
  const baseUrl = apiConfig.API_ROOT_URL;
  const queryParamsStr = getQueryParamsStr({ documentId, ac: true });

  copyToClipboard(`${baseUrl}${queryParamsStr}`).then(() => {
    successToast({message: "Link has been copied"});
  });
};
