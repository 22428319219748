import ReactSelect from "react-select";
import clsx from "clsx";
import styles from "./Select.module.scss";

export type CustomSelectProps = {
  title?: string;
  removeDropdownIndicator?: boolean;
  error?: boolean;
  requiredStyles?: boolean;
  onCreateOption?: (value: string) => void;
};

export const selectClassNames = (
  error: boolean
): React.ComponentProps<typeof ReactSelect>["classNames"] => ({
  control: (state) =>
    clsx(
      !error && styles.control,
      error && styles.error,
      state.isFocused && state.menuIsOpen && styles.focused
    ),
  valueContainer: () => styles["value-container"],
  option: (state) =>
    clsx(
      (state.isFocused || state.isSelected) && styles["option-selected"],
      styles.option
    ),
  menu: () => styles.menu,
  menuList: () => styles["menu-list"],
  indicatorsContainer: () => styles.indicators,
});

export const dropdownIndicator = (removeDropdownIndicator: boolean) =>
  removeDropdownIndicator ? null : (
    <span className={clsx("Appkit4-icon", "icon-down-chevron-outline")}></span>
  );
