import ReactSelect from "react-select";
import { CustomSelectProps, dropdownIndicator, selectClassNames } from "./common";
import CustomControl from "./components/CustomControl";

type Props = React.ComponentProps<typeof ReactSelect> & CustomSelectProps;

export default function Select({ ...props }: Props) {
  const { removeDropdownIndicator, error } = props;

  return (
    <ReactSelect
      {...props}
      classNames={selectClassNames(!!error)}
      components={{
        DropdownIndicator: () => dropdownIndicator(!!removeDropdownIndicator),
        Control: CustomControl,
        IndicatorSeparator: null,
        ...props.components,
      }}
      placeholder={props.placeholder || ""}
      backspaceRemovesValue
    />
  );
}
