import React, { useContext, useEffect, useState } from "react";
import { Entity } from "../types/Entity";
import { Api } from "../api/apiHelper";
import { signerRoles as signerRolesApi } from "../api/endpoints/signers";

type AppContextType = {
  signerRoles: Entity[];
};

type AppContextProviderProps = {
  children: React.ReactNode;
};

const AppContext = React.createContext<AppContextType>({} as AppContextType);

const AppContextProvider: React.FC<AppContextProviderProps> = (props) => {
  const [signerRoles, setSignerRoles] = useState<Entity[]>([]);

  const appContext: AppContextType = {
    signerRoles,
  };

  useEffect(() => {
    Api.get(signerRolesApi).then(setSignerRoles);
  }, []);

  return (
    <AppContext.Provider value={appContext}>
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextType => useContext(AppContext);

export default AppContextProvider;
