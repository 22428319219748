import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Row as TanstackRow } from "@tanstack/react-table";
import { useFormik } from "formik";
import {
  Button,
  Checkbox,
  Input,
  Modal,
  TextArea,
} from "@appkit4/react-components";
import { allSubCategoriesByCategoryId } from "../../../../../api/endpoints/categories";
import { Api } from "../../../../../api/apiHelper";
import Table from "../../../../../components/Table";
import { Category, NewCategory } from "../../../../../types/Category";
import { createSubCategoryColumns } from "../../helpers/createSubCategoryColumns";
import { createNewSubCategory } from "../../helpers/createNewRow";
import { CategoryPayload } from "../../types/CategoryPayload";
import { isExistingCategory } from "../../types/typeGuards";
import { errorToast } from "../../../../../components/Toast";
import styles from "./EditCategoryModal.module.scss";
import { useConfirmationModalContext } from "../../../../../contexts/ConfirmationModalContext";
import PageSpinner from "../../../../../components/PageSpinner";
import { formatPayload } from "../../helpers/formatPayload";
import { CategoryForm } from "../../types/CategoryForm";
import { validationSchema } from "../../helpers/validationSchema";

type Props = {
  category: Category | NewCategory;
  onClose: () => void;
  onConfirm: (value: CategoryPayload) => Promise<void>;
};

export default function EditCategoryModal({
  category,
  onClose,
  onConfirm,
}: Props) {
  const [isLoadingSubCategories, setIsLoadingSubCategories] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isEditingMode = isExistingCategory(category);

  const modalContext = useConfirmationModalContext();
  const modalContainer = document.getElementById("root");

  const formik = useFormik<CategoryForm>({
    initialValues: {
      name: category.name,
      description: category.description,
      isActive: category.isActive,
      subCategories: isEditingMode ? [] : [createNewSubCategory(category.isActive)],
    },
    validationSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      onConfirm(formatPayload(values, category)).finally(() =>
        setIsSubmitting(false)
      );
    },
  });

  useEffect(() => {
    if (!isEditingMode) return;

    setIsLoadingSubCategories(true);
    Api.get(allSubCategoriesByCategoryId(category.id))
      .then((res) => {
        formik.setFieldValue("subCategories", res);
      })
      .catch(() => errorToast({ message: "Failed to load the subcategories" }))
      .finally(() => setIsLoadingSubCategories(false));
  }, [category]);

  const deleteSubCategory = (row: Category) => {
    modalContext
      .showConfirmation(
        "Delete SubCategory",
        "Are you sure you want to delete this subCategory?"
      )
      .then((isConfirmed) => {
        if (!isConfirmed) return;
        formik.setFieldValue(
          "subCategories",
          formik.values.subCategories.filter((item) => item.id !== row.id)
        );
      });
  };

  const reorderSubCategories = (reordered: Category[]) => {
    formik.setFieldValue("subCategories", reordered);
  };

  const editCell = (
    row: TanstackRow<Category>,
    columnId: string,
    value: string
  ) =>
    formik.setFieldValue(
      "subCategories",
      formik.values.subCategories.map((item) =>
        item.id === row.id ? { ...item, [columnId]: value } : item
      )
    );

  const addSubCategory = () => {
    formik.setFieldValue("subCategories", [
      createNewSubCategory(formik.values.isActive),
      ...formik.values.subCategories,
    ]);
  };

  const columns = useMemo(
    () => createSubCategoryColumns(deleteSubCategory, formik.values.isActive),
    [formik.values?.subCategories, formik.values.isActive]
  );

  return (
    <Modal
      title={isEditingMode ? "Edit Category" : "Add New Category"}
      onCancel={onClose}
      visible={!!category}
      initialFocus={false}
      className={styles.modal}
      container={modalContainer ? { node: modalContainer, toRoot: true } : undefined}
      footer={
        <>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={formik.handleSubmit} loading={isSubmitting}>
            Confirm
          </Button>
        </>
      }
    >
      <div className={styles.inputs}>
        <Input
          name="name"
          title="Category Name"
          onChange={(_v: string, event: ChangeEvent<HTMLInputElement>) =>
            formik.handleChange(event)
          }
          value={formik.values.name}
          error={formik.touched.name && !!formik.errors.name}
        />
        <TextArea
          name="description"
          title="Category Description"
          onChange={(_v: string, event: ChangeEvent<HTMLTextAreaElement>) =>
            formik.handleChange(event)
          }
          value={formik.values.description}
          autosize={{ maxRows: 2 }}
          error={formik.touched.description && !!formik.errors.description}
        />
        <Checkbox
          onChange={(checked: boolean) =>
            formik.setFieldValue("isActive", checked)
          }
          checked={formik.values.isActive}
        >
          Is Active
        </Checkbox>
      </div>
      {isLoadingSubCategories ? (
        <PageSpinner />
      ) : (
        <>
          <div className={styles.subcategories}>
            <div className="ap-typography-heading-s">SubCategories</div>
            <Button kind="secondary" onClick={addSubCategory}>
              Add SubCategory
            </Button>
          </div>
          <Table
            data={formik.values?.subCategories}
            columns={columns}
            onRowReordering={reorderSubCategories}
            onCellEdit={editCell}
            striped
            condensed
          />
        </>
      )}
    </Modal>
  );
}
