import { AdminSection } from "../../../types/AdminSection";

export const adminSections: AdminSection[] = [
  {
    id: "users",
    link: "users",
    title: "Users",
    description: "Manage Users & Permissions.",
    color: "#FB7C4D",
    icon: "avatar-outline",
  },
  {
    id: "categories",
    link: "categories",
    title: "Categories",
    description: "Manage Categories & SubCategories.",
    color: "#0089EB",
    icon: "3by3grid-outline",
  },
  {
    id: "guidances",
    link: "guidances",
    title: "Guidance Points",
    description: "Manage Guidances.",
    color: "#4EB523",
    icon: "toggle-outline",
  },
  {
    id: "editor-notes",
    link: "editor-notes",
    title: "Editor Notes",
    description: "Manage Editor Notes.",
    color: "#B056F6",
    icon: "note-outline",
  },
  {
    id: "templates",
    link: "templates",
    title: "Templates & SubTemplates",
    description: "Manage templates & subTemplates.",
    color: "#E0301E",
    icon: "document-multiple-outline",
  },
  {
    id: "forms",
    link: "forms",
    title: "Set Form Coordinates",
    description: "Manage and set the coordinates for Signature Box and Date entry for your form.",
    color: "#FFC83D",
    icon: "pin-outline",
  },
  {
    id: "doc-bot-templates",
    link: "doc-bot-templates",
    title: "DocBot Templates",
    description: "Manage DocBot Templates",
    color: "#49ABA0",
    icon: "document-file-outline",
  },
  {
    id: "app-settings",
    link: "settings",
    title: "Application Settings",
    description: "Manage Application Settings",
    color: "#4458c7",
    icon: "setting-outline",
  },
];
