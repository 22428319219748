import { DocBotTemplatesRes } from "../types/DocBotTemplatesRes";

export const formatTableData = (data: DocBotTemplatesRes[]) =>
  data.map((i) => ({
    id: i.id,
    name: i.name,
    docBotId: i.docBotId,
    templates: i.templates
      ?.map((template) => {
        const subTemplates = template.subTemplates
          .map((subTemplate) => subTemplate.name)
          .join(", ");
        return `${template.name} (${subTemplates})`;
      })
      .join(", "),
  }));
