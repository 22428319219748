import { useContext } from "react";
import guestConfigs from "../../configs/guest-configs.json";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import { SpaContainer } from "../../components/sdk-framework/SpaContainer";
import { UnderMaintenance } from "../../components/UnderMaintenance";
import { HOST_APP_BASE_URL } from "../../constants/hostAppBaseUrl";
import { useSyncNavigation } from "../../hooks/useSyncNavigation";
import { useUnderMaintenance } from "../../hooks/useUnderMaintenance";
import { UserContext } from "../../contexts/UserContext";
import { TRACK_MFE_CONFIG_NAME } from "../../constants/trackMfeConfigName";

const SyncNavigationHandler = () => {
  useSyncNavigation();
  const { currentUser } = useContext(UserContext);

  let guestsConfig =
    guestConfigs?.[process.env.REACT_APP_CONFIG_ENV ?? "local"];

  // Replacing ##SAS_TOKEN## to real value supposing token can be in mulitple places
  guestsConfig = JSON.parse(
    JSON.stringify(guestsConfig)?.replace(
      /##SAS_TOKEN##/g,
      process.env.REACT_APP_CDN_SAS_TOKEN ?? ""
    )
  );
  const trackConfig = guestsConfig.find(
    (c) => c.name === TRACK_MFE_CONFIG_NAME
  );

  return (
    <SpaContainer
      className="c4t-spa-container"
      guestConfig={trackConfig}
      user={currentUser}
      onProgressComponent={<LoadingPlaceholder overlay="parent" />}
      onErrorComponent={<div className="d-flex">MFE loading error!</div>}
      hostAppBaseUrl={HOST_APP_BASE_URL}
    />
  );
};

export const MfeRoutesHandler = () => {
  const { isLoading, isUnderMaintenance } = useUnderMaintenance();

  return (
    <>
      {isLoading ? (
        <LoadingPlaceholder overlay="parent" />
      ) : isUnderMaintenance ? (
        <UnderMaintenance />
      ) : (
        <SyncNavigationHandler />
      )}
    </>
  );
};
