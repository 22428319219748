import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import Redirect from "../../components/Redirect";
import { UnderMaintenance } from "../../components/UnderMaintenance";
import { DOCUMENT_ID_QUERY_PARAM } from "../../constants/documentIdQueryParam";
import { useUnderMaintenance } from "../../hooks/useUnderMaintenance";
import TrackContainer from "../../pages/TrackDocuments";

export const TrackDocumentsRouteHandler = () => {
  const { isLoading, isUnderMaintenance } = useUnderMaintenance();

  const urlParams = new URLSearchParams(window.location.search.toLowerCase());
  const documentIdFromURL = urlParams.get(
    DOCUMENT_ID_QUERY_PARAM.toLowerCase()
  );
  return (
    <>
      {isLoading ? (
        <LoadingPlaceholder overlay="parent" />
      ) : isUnderMaintenance ? (
        <UnderMaintenance />
      ) : documentIdFromURL ? (
        <Redirect documentId={documentIdFromURL} />
      ) : (
        <TrackContainer />
      )}
    </>
  );
};
