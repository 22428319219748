import AsyncReactSelect from "react-select/async";
import {
  CustomSelectProps,
  dropdownIndicator,
  selectClassNames,
} from "./common";
import CustomControl from "./components/CustomControl";

type Props = React.ComponentProps<typeof AsyncReactSelect> & CustomSelectProps;

export default function AsyncSelect({ ...props }: Props) {
  const { removeDropdownIndicator, error } = props;

  return (
    <AsyncReactSelect
      {...props}
      classNames={selectClassNames(!!error)}
      components={{
        DropdownIndicator: () => dropdownIndicator(!!removeDropdownIndicator),
        Control: CustomControl,
        IndicatorSeparator: null,
        ...props.components,
      }}
      placeholder={props.placeholder || ""}
      backspaceRemovesValue
      isClearable
    />
  );
}
