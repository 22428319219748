import { Button, Input, Modal } from "@appkit4/react-components";
import { TextEditor } from "@appkit4/react-text-editor";
import { GuidancePoint } from "../../../../../types/Guidance";
import {
  modalBodyFullHeight,
  textEditorStyle,
} from "../../../EditorNotes/helpers/inlineStyles";
import styles from "../../Guidances.module.scss";
import { toolBarSettings } from "../../../EditorNotes/helpers/toolBarSettings";
import { Api } from "../../../../../api/apiHelper";
import { guidancePoint } from "../../../../../api/endpoints/guidances";
import { useEffect, useState } from "react";
import LoadingPlaceholder from "../../../../../components/LoadingPlaceholder";
import { useFormik } from "formik";
import validationSchema from "./helpers/validationSchema";

type Props = {
  currentPoint: GuidancePoint | null;
  onSave: () => void;
  onCancel: () => void;
};

const GuidancePointModal = ({ currentPoint, onSave, onCancel }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      id: "",
      title: "",
      description: "",
    },
    validationSchema,
    onSubmit: (value: GuidancePoint) => {
      upsertPoint(value);
    },
  });

  useEffect(() => {
    if (currentPoint) form.setValues(currentPoint);
  }, [currentPoint]);

  const upsertPoint = (point: GuidancePoint) => {
    setIsLoading(true);
    const endpoint = point.id ? Api.put : Api.post;

    endpoint(guidancePoint(point.id), point)
      .then(() => {
        onSave();
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <LoadingPlaceholder overlay="parent" />}
      <Modal
        visible={!!currentPoint}
        onCancel={onCancel}
        className={styles.modal}
        title={
          currentPoint?.id ? "Edit Guidance Point" : "Create Guidance Point"
        }
        bodyStyle={modalBodyFullHeight}
        footer={
          <>
            <Button kind="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={form.submitForm} loading={isLoading}>
              Save
            </Button>
          </>
        }
      >
        <div className={styles.editorContainer}>
          <Input
            value={form.values.title}
            title="Name"
            onChange={(val: string) => form.setFieldValue("title", val)}
            error={form.touched.title && !!form.errors.title}
            errorNode={form.errors.title}
          ></Input>
          <TextEditor
            onChange={(event, editor, data) =>
              form.setFieldValue("description", data)
            }
            data={form.values.description || ""}
            config={{
              toolbar: toolBarSettings,
            }}
            style={textEditorStyle}
          ></TextEditor>
          {form.touched.description && !!form.errors.description && (
            <div className="field-error">{form.errors.description}</div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default GuidancePointModal;
