import { Avatar, DropdownButton } from "@appkit4/react-components";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { getInitials } from "../../../helpers/getInitials";
import { DROPDOWN_OPTIONS } from "./constants";
import { UserDropdownOption } from "./types";
import apiConfig from "../../../api/apiConfig";

export const UserTemplate = () => {
  const { currentUser, msalInstance } = useContext(UserContext);
  const userInitials = currentUser ? getInitials(currentUser?.name) : "";

  const selectActions = {
    [UserDropdownOption.LOGOUT]: () => {
      const logoutRequest = {
        mainWindowRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
        account: msalInstance?.getActiveAccount(),
        idTokenHint: sessionStorage.getItem(apiConfig.C4T_ID_TOKEN) ?? "",
      };

      msalInstance?.logoutPopup(logoutRequest);
    },
  };

  return (
    <DropdownButton
      customTriggerNode={true}
      onSelect={(value) => selectActions[value]()}
      data={DROPDOWN_OPTIONS}
      prefixTemplate={(item) => (
        <span className={`Appkit4-icon icon-${item.iconName}`}></span>
      )}
    >
      <Avatar size={32} label={userInitials}></Avatar>
    </DropdownButton>
  );
};
