import { Navigate, Outlet } from "react-router-dom";
import IUserProps from "../../types/IUserProps";
import { USER_ROLES } from "../../types/UserRoles";
import styles from "./AdminLayout.module.scss";

type Props = {
  user?: IUserProps;
};

export const AdminLayout = ({ user }: Props) => {
  if (user?.role.includes(USER_ROLES.admin)) {
    return (
      <div className={styles.wrapper}>
        <Outlet />
      </div>
    );
  }

  return <Navigate to={"/"} replace />;
};
