type Props = {
  message: string;
};
export const FieldError = ({ message }: Props) => {
  return (
    <span className="ap-typography-body-s ap-text-states-error-05">
      {message}
    </span>
  );
};
