import { ChangeEvent } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Input, Modal } from "@appkit4/react-components";
import { Option } from "../../../../../types/Dropdown";
import AsyncSelect from "../../../../../components/Select/AsyncSelect";
import { useLoadUserOptions } from "../../../../../hooks/useLoadUserOptions";
import { stringToOption } from "../../../../../helpers/stringToOption";
import { UserDropdownOption } from "../../../../../types/Dropdown";
import UserOption from "../../../../../components/Select/components/UserOption";
import Select from "../../../../../components/Select";
import { User, UserRow } from "../../../../../types/User";
import styles from "./CreateUserModal.module.scss";

type Props = {
  onClose: () => void;
  onConfirm: (value: User) => void;
  userRoles: Option[];
};

const userInitialValues = {
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  pwCPPI: "",
  pwcGuid: "",
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  roles: Yup.array()
    .of(Yup.object().shape({ value: Yup.string(), label: Yup.string() }))
    .min(1)
    .required(),
});

export default function CreateUser({ onClose, onConfirm, userRoles }: Props) {
  const form = useFormik<Omit<UserRow, "id">>({
    initialValues: {
      ...userInitialValues,
      roles: [],
      isAdmin: false,
    },
    validationSchema,
    onSubmit: (val) => {
      const userPayload = {
        ...val,
        roleIds: val.roles.map((i) => i.value),
      };
      onConfirm(userPayload);
    },
  });

  const { loadUserOptions } = useLoadUserOptions([]);

  return (
    <Modal
      title="Create User"
      onCancel={onClose}
      initialFocus={false}
      className={styles.modal}
      visible
      footer={
        <>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={form.handleSubmit}>Confirm</Button>
        </>
      }
    >
      <div className="ap-full-width ap-flex ap-flex-direction-column">
        <AsyncSelect
          title="Search"
          requiredStyles
          className="ap-mb-spacing-3"
          removeDropdownIndicator
          loadOptions={loadUserOptions}
          value={form.values?.name && stringToOption(form.values?.name)}
          error={form.touched?.name && !!form.errors?.name}
          components={{ Option: UserOption }}
          //@ts-ignore
          onChange={(val: UserDropdownOption) => {
            form.setValues(
              val
                ? {
                    ...form.values,
                    name: val.label,
                    firstName: val.firstName,
                    lastName: val.lastName,
                    email: val.details || "",
                    pwCPPI: val.pwcPpi,
                    pwcGuid: val.pwcGuid || "",
                  }
                : {
                    ...form.values,
                    ...userInitialValues,
                  }
            );
          }}
        />

        <Input
          name="email"
          title="Email"
          onChange={(_v: string, event: ChangeEvent<HTMLInputElement>) =>
            form.handleChange(event)
          }
          value={form.values.email}
          error={form.touched.email && !!form.errors.email}
          className="ap-mb-spacing-3"
          disabled
        />

        <Select
          options={userRoles}
          value={form.values.roles}
          isMulti
          onChange={(value) => form.setFieldValue("roles", value)}
          error={form.touched.roles && !!form.errors.roles}
        />
      </div>
    </Modal>
  );
}
