import clsx from "clsx";
import styles from "./TileCard.module.scss";

type Props = {
  name: string;
  color: string;
  count: number;
  isSelected: boolean;
  onSelect: (value: boolean) => void;
};

export default function TileCard({
  name,
  color,
  count,
  isSelected,
  onSelect,
}: Props) {
  return (
    <div
      className={clsx(styles.tile, { [styles.active]: isSelected})}
      onClick={() => onSelect(!isSelected)}
    >
      <span className="ap-typography-body ap-mr-spacing-5">{name}</span>
      <span className="ap-typography-heading">{count}</span>
      <div style={{ backgroundColor: color }} />
    </div>
  );
}
