export enum StepId {
    CREATE = "Create",
    UPLOAD = "Upload",
    REVIEW = "Review",
    SIGN_OFF = "SignOff",
  }
  
  export enum CreateSectionId {
    DOC_BOT = "DocBot",
    PWC_CONTACT = "PwcContact",
  }