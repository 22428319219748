import { ReactNode } from "react";
import clsx from "clsx";
import imgSrc from "../../assets/GracefulError.png";
import styles from "./ErrorPage.module.scss";

type Props = {
  message: string | ReactNode;
  subMessage?: string | ReactNode;
};
const ErrorPage = ({ message, subMessage = "" }: Props) => {
  return (
    <div className={clsx(styles.page, "m-auto text-center")}>
      <img className={styles.image} src={imgSrc} alt="" />
      <div className={styles.message}>
        <p className="sdk-font-weight-medium mb-1">{message}</p>
      </div>
      {!!subMessage && (
        <div className="h7 pb-2">
          <p className="sdk-font-weight-medium">{subMessage}</p>
        </div>
      )}
    </div>
  );
};

export default ErrorPage;
