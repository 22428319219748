import { Entity } from "../../../../types/Entity";
import { CreateSectionId, StepId } from "../types/CreateDocumentSteps";

export const getCreateDocumentSteps = (isUploadEnabled: boolean) => [
  {
    id: StepId.CREATE,
    name: "Create",
  },
  ...(isUploadEnabled
    ? [
        {
          id: StepId.UPLOAD,
          name: "Upload",
        },
      ]
    : []),
  {
    id: StepId.REVIEW,
    name: "Review",
  },
  {
    id: StepId.SIGN_OFF,
    name: "Sign Off",
  },
];

const getCreateSubSteps = (exhibits: Entity[]) => [
  {
    id: CreateSectionId.PWC_CONTACT,
    name: "PwC Contact",
  },
  {
    id: CreateSectionId.DOC_BOT,
    name: "Interview",
  },
  ...exhibits,
];

export const getSubStepsToSteps = (exhibits: Entity[]) =>  ({
  [StepId.CREATE]: getCreateSubSteps(exhibits),
});
