import { Loading } from "@appkit4/react-components";
import clsx from "clsx";
import styles from "./LoadingPlaceholder.module.scss";

type Props = {
  overlay?: 'parent' | 'page';
  message?: string;
  className?: string;
};

export default function LoadingPlaceholder({
  overlay,
  message,
  className,
}: Props) {
  return (
    <div
      className={clsx(styles.root, className, {
        [styles.overlay]: overlay,
        [styles.overlayParent]: overlay === 'parent',
        [styles.overlayPage]: overlay === 'page',
      })}
    >
      {message && <div>{message}</div>}
      <Loading loadingType="circular" indeterminate />
    </div>
  );
}
