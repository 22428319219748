import clsx from "clsx";
import { Loading } from "@appkit4/react-components/loading";
import styles from "./PageSpinner.module.scss";

type Props = {
  text?: string;
  className?: string;
  overlayContent?: boolean;
};

const PageSpinner = ({ text, className, overlayContent }: Props) => {
  return (
    <div
      className={clsx(styles.spinnerContainer, className, {
        [styles.overlay]: overlayContent,
      })}
    >
      <div className={styles.spinner}>
        {text && <p>{text}</p>}
        <Loading loadingType="circular" indeterminate />
      </div>
    </div>
  );
};

export default PageSpinner;
