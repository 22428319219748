import { Button, Input, Select } from "@appkit4/react-components";
import { Option } from "../../../../../types/Dropdown";
import { Tag as SubTemplateTag } from "../../../../../types/Tag";
import { TagDataType } from "../../../../../types/TagDataType";
import { FieldError } from "../../../../../components/FieldError";
import { messages } from "../../../../../constants/messages";
import styles from "./Tag.module.scss";

const FIELD_KEY_TO_NAME = {
  name: "Tag Name",
  signerRoleId: "Signer Role",
  template: "Tag Template",
  dataType: "Tag Data Type",
};

type Props = {
  tag: SubTemplateTag;
  signerRoles: Option[];
  tagDataTypes: Option[];
  onDelete: (id: string) => void;
  onChange: (value: SubTemplateTag) => void;
  errors?: Record<string, string | undefined>;
};

export const Tag = ({
  tag,
  signerRoles,
  tagDataTypes,
  onDelete,
  onChange,
  errors,
}: Props) => {
  return (
    <>
      <div className={styles.wrapper}>
        <Input
          title={FIELD_KEY_TO_NAME.name}
          type="text"
          value={tag.name}
          onChange={(value: string) => onChange({ ...tag, name: value })}
          required
          error={!!errors?.name}
        />
        <Select
          placeholder={FIELD_KEY_TO_NAME.signerRoleId}
          data={signerRoles}
          dropdownRenderMode="portal"
          value={tag.signerRoleId}
          onSelect={(value) =>
            onChange({ ...tag, signerRoleId: value as string })
          }
          required
          error={!!errors?.signerRoleId}
        />
        <Input
          title={FIELD_KEY_TO_NAME.template}
          type="text"
          value={tag.template}
          onChange={(value: string) => onChange({ ...tag, template: value })}
          required
          error={!!errors?.template}
        />
        <Select
          placeholder={FIELD_KEY_TO_NAME.dataType}
          data={tagDataTypes}
          dropdownRenderMode="portal"
          onSelect={(value) =>
            onChange({ ...tag, dataType: value as TagDataType })
          }
          value={tag.dataType}
          required
          error={!!errors?.dataType}
        />
        <Button
          add
          kind="text"
          icon="icon-delete-outline"
          className="ap-text-neutral-10"
          onClick={() => onDelete(tag.id)}
        />
      </div>
      {!!errors && (
        <div className="ap-flex ap-flex-direction-column">
          {Object.entries(errors || {})
            .filter(([_, value]) => value !== messages.form.required)
            .map(([key, value]) => (
              <FieldError message={`${FIELD_KEY_TO_NAME[key]}: ${value}`} />
            ))}
        </div>
      )}
    </>
  );
};
