import { Button } from "@appkit4/react-components/esm/button";
import styles from "./PageFooter.module.scss";

type Props = {
  onClickBack: () => void;
  onSave: () => void;
  isSaving: boolean;
  secondaryActions?: React.ReactNode;
};

export const PageFooter = ({
  onClickBack,
  onSave,
  isSaving,
  secondaryActions,
}: Props) => {
  return (
    <div className={styles.footer}>
      <Button kind="secondary" onClick={onClickBack}>
        Back
      </Button>

      <div className="ap-flex ap-flex-gap-default">
        {secondaryActions}
        <Button onClick={onSave} loading={isSaving}>
          Save
        </Button>
      </div>
    </div>
  );
};
