import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <>
            <h1 className='mt-2 text-center'>This is empty host app for C4T MFE</h1>
            <div className='d-flex justify-content-center my-2'>
                <Link className='btn btn-primary' to={'track'}>Track Documents</Link>
            </div>
            <div className='d-flex justify-content-center my-2'>
                <a className="btn btn-primary" rel="noreferrer" href="https://dev.sightline-us.pwcinternal.com/portal/1/client/189/page/381/create-for-tax-2-local" target="_blank">Sightline Local MFE</a>
            </div>
        </>
    );
}

export default Home;