import { v4 as uuid } from "uuid";
import { TagType } from "../../../../types/TagType";

export const createNewTag = () => ({
  id: uuid(),
  name: "",
  signerRoleId: "",
  type: TagType.NAMED,
  template: "",
  dataType: "",
  isRequired: true,
  isNew: true,
});
