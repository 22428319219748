import { useState } from "react";
import { Button, Modal, Upload } from "@appkit4/react-components";
import { messages } from "../../../../../constants/messages";
import styles from "../Modal.module.scss";

type Props = {
  onConfirm: (file: File) => void;
  onClose: () => void;
  isLoading?: boolean;
};

export const DownloadFormWithTags = ({ onClose, onConfirm, isLoading }: Props) => {
  const [file, setFile] = useState<File | null>(null);

  const handleConfirm = () => {
    if (!file) return;
    onConfirm(file);
  };

  return (
    <Modal
      title="Upload form to get preview"
      onCancel={onClose}
      visible={true}
      initialFocus={false}
      maskCloseable={false}
      className={styles.modal}
      footer={
        <>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={!file} loading={isLoading} onClick={handleConfirm}>
            Get Preview
          </Button>
        </>
      }
    >
      <div>
        <Upload
          className={styles.upload}
          onChange={(file) => setFile(file.originFile)}
          acceptFileType={".pdf"}
          uploadInstruction={messages.upload.instruction}
        />
      </div>
    </Modal>
  );
};
