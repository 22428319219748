import { useContext, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { UserContext, IUserContext } from "../../contexts/UserContext";
import { USER_ROLES } from "../../types/UserRoles";
import styles from "./NavMenu.module.scss";
import { HOST_APP_BASE_URL } from "../../constants/hostAppBaseUrl";

const getRoutes = (isAdmin: boolean) => [
  {
    label: "Track Documents",
    path: "/",
    matchExpression: new RegExp(`^\/$|${HOST_APP_BASE_URL.replace("/",'')}`) ,
  },
  ...(isAdmin
    ? [
        {
          label: "Admin",
          path: "/admin",
          matchExpression: /^\/admin/,
        },
      ]
    : []),
];

interface INavItemProps {
  label: string;
  path: string;
  isActive?: boolean;
}

const NavItem = ({ label, path, isActive = false }: INavItemProps) => {
  return (
    <div className={clsx(styles.navItem, isActive && styles.active)}>
      <Link to={path}>{label}</Link>
    </div>
  );
};

const NavMenu = () => {
  const location = useLocation();
  const { currentUser } = useContext<IUserContext>(UserContext);

  const routes = useMemo(
    () => getRoutes(!!currentUser?.role.split(",").includes(USER_ROLES.admin)),
    [currentUser]
  );

  return (
    <div className={styles.navMenu}>
      {routes.map((r) => (
        <NavItem
          key={r.path}
          label={r.label}
          path={r.path}
          isActive={r.matchExpression?.test(location.pathname)}
        />
      ))}
    </div>
  );
};

export default NavMenu;
