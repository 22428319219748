import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FormikErrors, useFormik } from "formik";
import clsx from "clsx";
import {
  Button,
  Checkbox,
  Input,
  Panel,
  Select,
  TextArea,
  Combobox,
} from "@appkit4/react-components";
import { SubTemplate } from "../../../../../types/SubTemplate";
import { Tag as TagType } from "../../../../../types/Tag";
import { Api } from "../../../../../api/apiHelper";
import { getDocBotConfiguration } from "../../../../../api/endpoints/admin";
import { DocBotTemplate } from "../../../../../types/Template";
import { subTemplateValidationSchema } from "../../helpers/subTemplateValidationSchema";
import { documentSample } from "../../../../../api/endpoints/templates";
import { downloadFile } from "../../../../../helpers/downloadFile";
import {
  getCreateDocumentSteps,
  getSubStepsToSteps,
} from "../../constants/getCreateDocumentSteps";
import { createNewGuidance } from "../../helpers/createNewGuidance";
import { FieldError } from "../../../../../components/FieldError";
import { SubTemplateGuidance } from "../../types/SubTemplateGuidance";
import { Guidances } from "../Guidances";
import { Entity } from "../../../../../types/Entity";
import { useAppContext } from "../../../../../contexts/AppContext";
import { entityToOption } from "../../../../../helpers/entityToOption";
import { Tag } from "../Tag";
import { TAG_TYPE_OPTIONS } from "../../../../../constants/tagTypeOptions";
import { createNewTag } from "../../helpers/createNewTag";
import { createDocumentSampleFileName } from "../../helpers/createDocumentSampleFileName";
import { SubTemplateExhibit } from "../../types/SubTemplateExhibit";
import styles from "./SubTemplateForm.module.scss";

type Props = {
  subTemplate: SubTemplate;
  allSubTemplateNames: string[];
  onSave: (value: SubTemplate) => void;
  docDotTemplates: DocBotTemplate[];
  editorNotes: Entity[];
  guidances: Entity[];
  exhibits: SubTemplateExhibit[];
  templateName: string;
};

export const SubTemplateForm = forwardRef(function SubTemplateForm(
  {
    subTemplate,
    onSave,
    docDotTemplates,
    editorNotes,
    allSubTemplateNames,
    guidances,
    exhibits,
    templateName,
  }: Props,
  ref
) {
  const { signerRoles } = useAppContext();
  const form = useFormik<SubTemplate>({
    initialValues: subTemplate,
    validationSchema: subTemplateValidationSchema(allSubTemplateNames),
    onSubmit: (values) => onSave(values),
  });

  const [isLoadingDefaultConfig, setIsLoadingDefaultConfig] = useState(false);

  useEffect(() => {
    if (!form.values.documentSampleFileName) {
      form.setFieldValue(
        "documentSampleFileName",
        createDocumentSampleFileName(templateName, form.values.name)
      );
    }
  }, [subTemplate.documentSampleFileName, form]);

  const stepOptions = getCreateDocumentSteps(
    form.values.uploadDocumentsAllowed
  );

  const subStepsToSteps = getSubStepsToSteps(
    subTemplate.exhibitConfigurations?.length
      ? subTemplate.exhibitConfigurations
      : exhibits
  );

  const subTemplateSignerRoles = signerRoles.map(entityToOption);

  useImperativeHandle(
    ref,
    () => {
      return {
        onSubmit: () => form.submitForm(),
        errors: form.errors,
      };
    },
    [form]
  );

  const getDefaultConfiguration = () => {
    const docBotId = docDotTemplates.find(
      (i) => i.id === form.values.docBotTemplateId
    )?.docBotId;
    if (!docBotId) return;

    setIsLoadingDefaultConfig(true);
    Api.get(getDocBotConfiguration(docBotId))
      .then((res) => {
        form.setFieldValue(
          "documentConfiguration",
          JSON.stringify({ TypeOfAgreement: JSON.parse(res.TypeOfAgreement) })
        );
      })
      .finally(() => setIsLoadingDefaultConfig(false));
  };

  const downloadDocumentSample = () => {
    Api.getFile(documentSample(subTemplate.id)).then((res) => {
      res.blob().then((blob) => {
        const fileName =
          subTemplate.documentSampleFileName ||
          form.values.documentSampleFileName;

        downloadFile(new Blob([blob]), fileName ?? "document-sample.docx");
      });
    });
  };

  const deleteDocumentSample = () => {
    form.setValues({
      ...form.values,
      documentSampleFileName: undefined,
      sampleDocumentCode: undefined,
    });
  };

  const addGuidance = () => {
    form.setFieldValue("guidances", [
      ...(form.values.guidances || []),
      createNewGuidance(),
    ]);
  };

  const addTag = () => {
    form.setFieldValue("tags", [...(form.values.tags || []), createNewTag()]);
  };

  const deleteGuidance = (id: string) => {
    form.setFieldValue(
      "guidances",
      form.values.guidances?.filter((item) => item.id !== id)
    );
  };

  const deleteTag = (id: string) => {
    form.setFieldValue(
      "tags",
      form.values.tags?.filter((item) => item.id !== id)
    );
  };

  return (
    <div>
      <Input
        name="name"
        title="SubTemplate Name"
        onChange={(_v: string, event: ChangeEvent<HTMLInputElement>) =>
          form.handleChange(event)
        }
        value={form.values.name}
        error={form.touched.name && !!form.errors.name}
        className="ap-mb-spacing-4"
        errorNode={form.errors.name}
      />

      <Checkbox
        onChange={(value: boolean) => form.setFieldValue("isActive", value)}
        checked={form.values.isActive}
        className="ap-mb-spacing-4"
      >
        Is Active
      </Checkbox>

      <Checkbox
        onChange={(value: boolean) =>
          form.setFieldValue("hasPwcContact", value)
        }
        checked={form.values.hasPwcContact}
        className="ap-mb-spacing-4"
      >
        Has PwC Contact
      </Checkbox>
      <Checkbox
        onChange={(value: boolean) => form.setFieldValue("hasPwcSigner", value)}
        checked={form.values.hasPwcSigner}
        className="ap-mb-spacing-4"
      >
        Has PwC Signer
      </Checkbox>
      <Checkbox
        onChange={(value: boolean) =>
          form.setFieldValue("uploadDocumentsAllowed", value)
        }
        checked={form.values.uploadDocumentsAllowed}
        className="ap-mb-spacing-4"
      >
        Allow attachments upload
      </Checkbox>
      <Checkbox
        onChange={(value: boolean) =>
          form.setFieldValue("allowRedlining", value)
        }
        checked={form.values.allowRedlining}
        className="ap-mb-spacing-4"
      >
        Allow Redlining
      </Checkbox>

      <Select
        placeholder="DocBot Template"
        dropdownRenderMode="portal"
        className="ap-mb-spacing-4"
        data={docDotTemplates}
        onSelect={(value) => form.setFieldValue("docBotTemplateId", value)}
        value={form.values.docBotTemplateId}
        error={form.touched.docBotTemplateId && !!form.errors.docBotTemplateId}
        valueKey="id"
        labelKey="name"
      />

      <Button
        onClick={getDefaultConfiguration}
        kind="secondary"
        className="ap-mb-spacing-4"
        disabled={!form.values.docBotTemplateId}
        loading={isLoadingDefaultConfig}
      >
        Get Default Configuration
      </Button>

      <TextArea
        name="documentConfiguration"
        title="Document Configuration"
        onChange={(_v: string, event: ChangeEvent<HTMLTextAreaElement>) =>
          form.handleChange(event)
        }
        value={form.values.documentConfiguration}
        error={
          form.touched.documentConfiguration &&
          !!form.errors.documentConfiguration
        }
        className="ap-mb-spacing-4"
      />
      <Select
        placeholder="Editor Notes"
        dropdownRenderMode="portal"
        className="ap-mb-spacing-4"
        data={editorNotes}
        onSelect={(value) => form.setFieldValue("editorNotesId", value)}
        value={form.values.editorNotesId}
        valueKey="id"
        labelKey="name"
      />
      <Panel
        title={
          form.values.guidances?.length
            ? "Guidances"
            : "Guidances are not added"
        }
        extra={
          <Button
            kind="secondary"
            compact
            onClick={addGuidance}
            disabled={false}
          >
            Add Guidance
          </Button>
        }
        className={clsx(styles.panel, "ap-mb-spacing-4")}
      >
        {form.values.guidances?.map((value, idx) => {
          return (
            <Guidances
              key={value.id}
              guidance={value}
              onChange={(guidance) =>
                form.setFieldValue(`guidances[${idx}]`, guidance)
              }
              stepOptions={stepOptions}
              subStepOptions={subStepsToSteps[value.stepName || ""]}
              onDelete={deleteGuidance}
              errors={
                form.touched.guidances
                  ? (form.errors.guidances?.[
                      idx
                    ] as FormikErrors<SubTemplateGuidance>)
                  : undefined
              }
              guidanceOptions={guidances}
            />
          );
        })}
        {typeof form.errors.guidances === "string" &&
          form.touched.guidances &&
          !!form.errors.guidances && (
            <FieldError message={form.errors.guidances} />
          )}
      </Panel>

      <Panel
        title="Tags"
        extra={
          <Button kind="secondary" compact onClick={addTag} disabled={false}>
            Add Tag
          </Button>
        }
        className={clsx(styles.panel, "ap-mb-spacing-4")}
      >
        {form.values.tags?.map((tag, idx) => (
          <Tag
            key={tag.id}
            tag={tag}
            signerRoles={subTemplateSignerRoles}
            tagDataTypes={TAG_TYPE_OPTIONS}
            onDelete={deleteTag}
            onChange={(changedTag) =>
              form.setFieldValue(`tags[${idx}]`, changedTag)
            }
            errors={
              form.touched.tags
                ? (form.errors.tags?.[idx] as FormikErrors<TagType>)
                : undefined
            }
          />
        ))}
      </Panel>

      <Panel title="Exhibits" className={clsx(styles.panel, "ap-mb-spacing-4")}>
        <Combobox
          multiple
          placeholder="Search"
          className="ap-mb-spacing-3"
          data={exhibits}
          value={form.values?.exhibitConfigurations?.map((item) => item.id)}
          error={
            form.touched?.exhibitConfigurations &&
            !!form.errors?.exhibitConfigurations
          }
          onSelect={(val) =>
            form.setFieldValue(
              "exhibitConfigurations",
              exhibits.filter((exhibit) =>
                (val as string[]).includes(exhibit.id)
              )
            )
          }
          valueKey="id"
          labelKey="name"
          showSelectAll={false}
          dropdownRenderMode="portal"
          dropdownAlwaysDown={false}
        />
      </Panel>
      <div className={styles.editTemplateName}>
        <Input
          title="Document Sample Name"
          value={form.values.documentSampleFileName}
          onChange={(val: string) => {
            form.setFieldValue("documentSampleFileName", val);
          }}
          error={
            form.touched.documentSampleFileName &&
            !!form.errors.documentSampleFileName
          }
          errorNode={form.errors.documentSampleFileName}
          className="ap-mr-spacing-3"
        />

        <Input
          title="DocBot Origin Code"
          value={form.values.sampleDocumentCode}
          onChange={(val: string) =>
            form.setFieldValue("sampleDocumentCode", val)
          }
          error={
            form.touched.sampleDocumentCode && !!form.errors.sampleDocumentCode
          }
          errorNode={form.errors.sampleDocumentCode}
        />
        {subTemplate.sampleDocumentCode && (
          <Button
            add
            kind="text"
            icon="icon-document-download-outline"
            className="ap-text-neutral-10"
            onClick={downloadDocumentSample}
          />
        )}

        <Button
          add
          kind="text"
          icon="icon-delete-outline"
          className="ap-text-neutral-10"
          onClick={deleteDocumentSample}
        />
      </div>
    </div>
  );
});
