import { Button } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import LoadingPlaceholder from "../../../../../components/LoadingPlaceholder";
import { GuidancePoint } from "../../../../../types/Guidance";
import Table from "../../../../../components/Table";
import { Api } from "../../../../../api/apiHelper";
import {
  deleteGuidancePoint,
  getAllPoints,
} from "../../../../../api/endpoints/guidances";
import { getTableColumns } from "./helpers/getTablecolumns";
import GuidancePointModal from "./GuidancePointModal";
import { useConfirmationModalContext } from "../../../../../contexts/ConfirmationModalContext";
import styles from "../../Guidances.module.scss";
import { clsx } from "clsx";

const ManageGuidancePoints = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [points, setPoints] = useState<GuidancePoint[]>([]);
  const [currentPoint, setCurrentPoint] = useState<GuidancePoint | null>(null);

  const confirmationModal = useConfirmationModalContext();

  useEffect(() => {
    loadGuidancePoints();
  }, []);

  const loadGuidancePoints = () => {
    setIsLoading(true);
    Api.get(getAllPoints())
      .then((res) => {
        setPoints(res);
      })
      .finally(() => setIsLoading(false));
  };

  const onEdit = (point: GuidancePoint) => {
    setCurrentPoint(point);
  };

  const onDelete = (pointId: string) => {
    setIsLoading(true);

    confirmationModal
      .showConfirmation(
        "Confirmation",
        "Are you sure you want to delete guidance point?"
      )
      .then((isConfirmed: boolean) => {
        isConfirmed && deletePoint(pointId, false);
      });
  };

  const deletePoint = (pointId: string, force: boolean) => {
    Api.delete(deleteGuidancePoint(pointId, force))
      .then(() => {
        loadGuidancePoints();
      })
      .catch((ex) => {
        ex.response.json().then((ex) => {
          confirmationModal
            .showConfirmation("Are you sure you want to proceed?", ex.errors)
            .then(
              (isConfirmed: boolean) =>
                isConfirmed && deletePoint(pointId, true)
            );
        });
      })
      .finally(() => setIsLoading(false));
  };

  const onPointSave = () => {
    setCurrentPoint(null);
    loadGuidancePoints();
  };

  const tableColumns = getTableColumns(onEdit, onDelete);
  return (
    <>
      {isLoading && <LoadingPlaceholder overlay="parent" />}

      <div className={styles.pointsContainer}>
        <Button
          kind="secondary"
          className={clsx(styles.addButton)}
          onClick={() =>
            setCurrentPoint({ id: "", title: "", description: "" })
          }
        >
          Add New
        </Button>
        <Table data={points} columns={tableColumns} striped />
      </div>

      <GuidancePointModal
        currentPoint={currentPoint}
        onSave={onPointSave}
        onCancel={() => setCurrentPoint(null)}
      />
    </>
  );
};

export default ManageGuidancePoints;
