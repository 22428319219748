const MFE_ROUTES = {
  HOME: "/",
  TRACK: "/track",
  TYPE: "/type",
  ENGAGEMENT: "/engagement",
  TEMPLATES: "/templates",
  DOWNLOAD_TEMPLATES: "/download-templates",
  DETAILS: "/details",
  CREATE: "/create",
  REVIEW: "/review",
  ATTACHMENTS: "/attachments",
  INDIVIDUALS_WIZARD: "/individuals-wizard",
  ENTITIES_WIZARD: "/entities-wizard",
  CUSTOM_DOCUMENT: "/custom-document",
};

export default MFE_ROUTES;
