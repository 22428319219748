import { createColumnHelper } from "@tanstack/react-table";
import { DocBotTemplateRow } from "../types/DocBotTemplateTableRow";
import { Button } from "@appkit4/react-components";

const columnHelper = createColumnHelper<DocBotTemplateRow>();

export const createColumns = (onEdit: (id: string) => void) => [
  columnHelper.accessor("name", {
    id: "name",
    header: () => "Name",
  }),
  columnHelper.accessor("docBotId", {
    id: "docBotId",
    header: () => "DocBot Id",
  }),
  columnHelper.accessor("templates", {
    id: "templates",
    header: () => "Templates/SubTemplates",
    meta: {
      hasTooltip: true,
    },
  }),
  columnHelper.accessor("id", {
    header: () => <span>Actions</span>,
    size: 100,
    cell: (row) => (
      <div className="ap-flex ap-align-items-center ap-justify-content-end">
        <Button
          onClick={() => onEdit(row.row.id)}
          kind="text"
          icon="icon-edit-outline"
          className="ap-button-icon-only"
        />
      </div>
    ),
  }),
];
