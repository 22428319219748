import { DocumentStatus } from "../../../types/Document";
import { DOCUMENT_TILE_ALL, DOCUMENT_TILE_SIGNATURES, TileName } from "../types";

export const AWAITING_SIGNATURE_STATUSES = [
  DocumentStatus.AwaitingClientSignature,
  DocumentStatus.AwaitingPwCSignature,
  DocumentStatus.AwaitingPaperSignature,
];

export const configureStatusFilterParams = (status: TileName | null) => {
  switch (status) {
    case DOCUMENT_TILE_ALL:
      //@ts-ignore
    case null || undefined:
      return [];
    case DOCUMENT_TILE_SIGNATURES:
      return AWAITING_SIGNATURE_STATUSES;
    default:
      return [status];
  }
};
