import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HOST_APP_BASE_URL } from "../constants/hostAppBaseUrl";

export const useSyncNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname.replace(HOST_APP_BASE_URL, "");

    const handleNavigate = ({ detail }: CustomEvent<string>) => {
      if (detail === path) return;
      const newPath = detail === "/" ? detail : detail.replace("/", "");

      navigate(newPath);
    };

    window.addEventListener("navigateC4T", handleNavigate as EventListener);

    return () => {
      window.removeEventListener(
        "navigateC4T",
        handleNavigate as EventListener
      );
    };
  }, [location]);
};
