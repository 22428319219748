import { Modal } from "@appkit4/react-components";
import styles from "./SlideOut.module.scss";

type Props = React.ComponentProps<typeof Modal>;

export const SlideOut = (props: Props) => {
  return (
    <Modal
      {...props}
      className={styles.slideOut}
      placement={props.placement || "right"}
      wrapperStyle={{
        alignItems: "initial",
        ...props.wrapperStyle,
      }}
      modalStyle={{
        width: 480,
        display: "flex",
        flexDirection: "column",
        ...props.modalStyle,
      }}
      bodyStyle={{
        padding: 20,
        maxHeight: "initial",
        flexGrow: 1,
        borderTop: "1px solid #DDDDDD",
        borderBottom: "1px solid #DDDDDD",
        ...props.bodyStyle,
      }}
    >
      {props.children}
    </Modal>
  );
};
