import {
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import _, { isEmpty, isEqual } from "lodash";
import {
  ColumnState,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
  SortChangedEvent,
} from "ag-grid-community";
import {
  TileName,
  CountsByStatusGroup,
  DOCUMENT_TILE_ALL,
  DOCUMENT_TILE_SIGNATURES,
  GridState,
  FilterMetaDataRes,
  FilterMetaData,
  DocumentsData,
} from "./types";
import TrackPresenter from "./TrackPresenter";
import createTilesView from "./createTilesView";
import {
  deactivateDocument,
  getAGDocumentsApi,
  getDocumentFilterMetadataApi,
} from "../../api/endpoints/documents";
import { Api } from "../../api/apiHelper";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import TrackDetails from "./TrackDetails";
import { DocumentStatus, IDocument } from "../../types/Document";
import { UserContext } from "../../contexts/UserContext";
import { ALLOWED_TO_DOWNLOAD_ROLES } from "../../constants/allowedToDownloadRoles";
import {
  AWAITING_SIGNATURE_STATUSES,
  configureStatusFilterParams,
} from "./helpers/configureStatusFilterParams";
import { getSelectedTile } from "./helpers/getSelectedTile";
import { getColumnDefs } from "./helpers/getColumnDefs";
import copyDocumentLinkToClipboard from "../../helpers/copyDocumentLinkToClipboard";
import { successToast } from "../../components/Toast";
import useSessionStorage from "../../hooks/useSessionStorage";
import { useConfirmationModalContext } from "../../contexts/ConfirmationModalContext";
import { HOST_APP_BASE_URL } from "../../constants/hostAppBaseUrl";
import { getNavigationLink } from "./helpers/getNavigationLink";

export default function TrackContainer() {
  const { currentUser } = useContext(UserContext);
  const confirmationModal = useConfirmationModalContext();
  const navigate = useNavigate();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridState, setGridState] = useSessionStorage<GridState | null>(
    "c4t-track-documents-grid",
    null
  );
  const [filterMetaData, setFilterMetaData] = useState<FilterMetaData | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [countsByStatusGroup, setCountsByStatusGroup] =
    useState<CountsByStatusGroup>({
      Draft: 0,
      InReview: 0,
      AwaitingSignature: 0,
      Completed: 0,
      Expired: 0,
      All: 0,
    } as CountsByStatusGroup);
  const [detailsId, setDetailsId] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const tilesView = useMemo(
    () => createTilesView(countsByStatusGroup),
    [countsByStatusGroup]
  );
  const [selectedStatuses, setSelectedStatuses] = useState<DocumentStatus[]>(
    []
  );

  const stopGridRefresh = useRef(false);
  const initialGridState = useRef<GridState | null>(gridState);
  const documentsRef = useRef<DocumentsData>({ rows: [], count: 0 });

  useEffect(() => {
    if (isEqual(gridState, initialGridState.current)) return;
    initialGridState.current = null;
  }, [gridState]);

  const searchQuery = useDebouncedValue(searchText, searchText ? 1000 : 0);

  const serverSideDataSource = useMemo(() => {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        params.api.hideOverlay();
        if (stopGridRefresh.current) {
          !documentsRef.current.rows.length
            ? params.api.showNoRowsOverlay()
            : params.api.hideOverlay();
          params.success({
            rowData: documentsRef.current.rows,
            rowCount: documentsRef.current.count,
          });
          stopGridRefresh.current = false;
          return;
        }

        const requestParams =
          !initialGridState.current ||
          (initialGridState.current && isEmpty(initialGridState?.current))
            ? { ...params.request, quickFilterText: searchQuery }
            : {
                ...params.request,
                filterModel: initialGridState?.current?.filterModel || {},
                sortModel: initialGridState?.current?.sortModel || [],
                quickFilterText: searchQuery,
              };

        setLoading(true);
        Api.post(getAGDocumentsApi(), requestParams)
          .then((res) => res.json())
          .then((data) => {
            documentsRef.current = { rows: data.items, count: data.total };
            !data.items.length
              ? params.api.showNoRowsOverlay()
              : params.api.hideOverlay();
            params.success({ rowData: data.items, rowCount: data.total });
          })
          .catch(() => params.fail())
          .finally(() => setLoading(false));
      },
    };
  }, [searchQuery]);

  const isTemplateDownloadAllowed = ALLOWED_TO_DOWNLOAD_ROLES.some((role) =>
    currentUser?.role.includes(role)
  );

  const setTile = (id: TileName | null) => {
    const tileStatuses = configureStatusFilterParams(id);
    setSelectedStatuses(tileStatuses as DocumentStatus[]);

    let gridFilterModel = gridApi?.getFilterModel();
    gridApi?.setFilterModel({
      ...gridFilterModel,
      status: !tileStatuses?.length
        ? undefined
        : { filterType: "set", values: tileStatuses },
    });
  };

  useEffect(() => {
    Api.get(getDocumentFilterMetadataApi)
      .then((data: FilterMetaDataRes) => {
        const { engagements, subTemplates, clients, statusCounts } = data;
        setFilterMetaData((prev) => ({
          ...prev,
          subTemplates,
          engagements,
          clients,
        }));
        setCountsByStatusGroup({
          Draft: statusCounts[DocumentStatus.Draft] || 0,
          InReview: statusCounts[DocumentStatus.InReview] || 0,
          Completed: statusCounts[DocumentStatus.Completed] || 0,
          Expired: statusCounts[DocumentStatus.Expired] || 0,
          [DOCUMENT_TILE_SIGNATURES]:
            (statusCounts[DocumentStatus.AwaitingClientSignature] || 0) +
            (statusCounts[DocumentStatus.AwaitingPwCSignature] || 0) +
            (statusCounts[DocumentStatus.AwaitingPaperSignature] || 0),
          [DOCUMENT_TILE_ALL]: Object.values(statusCounts).reduce(
            (cur: number, acc: number) => cur + acc,
            0
          ),
        } as CountsByStatusGroup);
      })
      .catch((err) => console.log(err));
  }, []);

  const navigateToDocument = (document: IDocument) => {
    navigate(
      `${HOST_APP_BASE_URL}${getNavigationLink(document)}/?documentId=${
        document.id
      }`
    );
  };

  const refreshStatusCount = (status: string, newValue: number) => {
    setCountsByStatusGroup((prev) => ({
      ...prev,
      [status]: newValue,
      All: --prev.All,
    }));
  };

  const handleDocumentRemoved = useCallback(
    (status: string) => {
      gridApi?.refreshServerSide();

      refreshStatusCount(status, --countsByStatusGroup[status]);
    },
    [gridApi, refreshStatusCount]
  );

  const handleDeleteDocument = useCallback(
    (documentId: string, status: DocumentStatus) => {
      confirmationModal
        .showConfirmation(
          "Confirmation",
          "Are you sure you want to delete the document?"
        )
        .then((isConfirmed: boolean) => {
          isConfirmed &&
            Api.delete(deactivateDocument(documentId)).then(() => {
              handleDocumentRemoved(status);

              successToast({ message: "Document was successfully deleted." });
            });
        });
    },
    [handleDocumentRemoved]
  );

  const handleGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (filterMetaData && gridApi) {
      if (!!gridState) {
        if (gridState.filterModel) {
          stopGridRefresh.current = true;
          gridApi.setFilterModel(gridState.filterModel ?? {});
        }
        if (gridState.sortModel?.length) {
          stopGridRefresh.current = true;
          gridApi.applyColumnState({
            state: gridState.sortModel,
          });
        }
      }
    }
  }, [filterMetaData]);

  const handleGridFilterChanged = (filterParams: FilterChangedEvent) => {
    const gridFilter = filterParams.api.getFilterModel();

    setGridState({
      filterModel: Object.keys(gridFilter).length ? gridFilter : undefined,
      sortModel: gridState?.sortModel,
    });

    const statusFilter = gridFilter?.status;
    if (
      !statusFilter ||
      !statusFilter.values?.length ||
      (statusFilter.values.length > 1 &&
        statusFilter.values.find(
          (s) => !AWAITING_SIGNATURE_STATUSES.includes(s)
        ))
    ) {
      setSelectedStatuses([]);
      return;
    }

    setSelectedStatuses(statusFilter.values);
  };

  const handleGridSortChanged = (sortParams: SortChangedEvent) => {
    let sortModel = [] as ColumnState[];
    if (sortParams.columns?.length) {
      sortModel = sortParams.columns.map(
        (c) =>
          ({
            colId: c.getColId(),
            sort: c.getSort(),
          } as ColumnState)
      );
    }

    setGridState({ filterModel: gridState?.filterModel ?? {}, sortModel });
  };

  const columns = useMemo(
    () =>
      getColumnDefs(
        setDetailsId,
        copyDocumentLinkToClipboard,
        navigateToDocument,
        handleDeleteDocument,
        filterMetaData,
        stopGridRefresh,
        initialGridState
      ),
    [filterMetaData]
  );

  useEffect(() => {
    //when filter-meta-data is returned after advanced-filter columns are recreated and grid loses its NoRowsOverlay-state
    //TODO: remove when all filter values are returned with separate api
    if (gridApi && !loading && !documentsRef.current.rows.length) {
      gridApi.showNoRowsOverlay();
    }
  }, [columns, loading]);

  return (
    <>
      <TrackPresenter
        columns={columns}
        tilesView={tilesView}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedTile={getSelectedTile(selectedStatuses)}
        setSelectedTile={setTile}
        loading={loading}
        isError={isError}
        legacyLink={undefined}
        isTemplateDownloadAllowed={isTemplateDownloadAllowed}
        onGridReady={handleGridReady}
        onFilterChanged={handleGridFilterChanged}
        onGridSortChanged={handleGridSortChanged}
        serverSideDataSource={serverSideDataSource}
        count={documentsRef.current.count}
        isFilterApplied={!isEmpty(gridState?.filterModel)}
      />
      <TrackDetails
        id={detailsId}
        rows={documentsRef.current.rows}
        close={() => setDetailsId(null)}
        handleDocumentRemove={handleDocumentRemoved}
      />
    </>
  );
}
