import { Button } from "@appkit4/react-components/button";
import { Checkbox } from "@appkit4/react-components/checkbox";
import _ from "lodash";

import { createColumnHelper } from "@tanstack/react-table";
import { Category } from "../../../../types/Category";
import {
  CustomColumn,
  EditableCellType,
} from "../../../../components/Table/types";
import styles from "../Category.module.scss";

const columnHelper = createColumnHelper<Category>();

export const createSubCategoryColumns = (
  onDelete: (row: Category) => void,
  isCategoryActive: boolean
) => [
  {
    id: CustomColumn.DRAGGABLE,
    size: 24,
    cell: () => <span className="Appkit4-icon icon-menu-outline"></span>,
  },
  columnHelper.accessor((row) => row.name, {
    id: "name",
    header: () => "Name",
    meta: {
      isEditable: true,
      type: EditableCellType.INPUT,
      // hasTooltip: true,
    },
  }),
  columnHelper.accessor("description", {
    id: "description",
    header: () => "Description",
    meta: {
      isEditable: true,
      type: EditableCellType.INPUT,
      // hasTooltip: true,
    },
  }),
  columnHelper.accessor("isActive", {
    id: "isActive",
    header: () => "Active",
    size: 100,
    meta: {
      isEditable: isCategoryActive,
      type: EditableCellType.CHECKBOX,
    },
    cell: (data) => (
      <Checkbox
        id={data.cell.id}
        className={styles.cursor}
        checked={isCategoryActive ? data.cell.getValue() : false}
      />
    ),
  }),
  columnHelper.accessor("id", {
    header: () => <span>Actions</span>,
    size: 60,
    cell: (row) => (
      <Button
        add
        kind="text"
        icon="icon-delete-outline"
        className="ap-text-neutral-10"
        onClick={() => onDelete(row.row.original)}
      />
    ),
  }),
];
