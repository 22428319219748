import { Category, NewCategory } from "../../../../types/Category";
import { CategoryForm } from "../types/CategoryForm";
import { isExistingCategory } from "../types/typeGuards";

export const formatPayload = (
  values: CategoryForm,
  category: Category | NewCategory
) => ({
  name: values.name,
  description: values.description,
  id: isExistingCategory(category) ? category.id : undefined,
  order: category.order,
  isActive: values.isActive,
  subCategories: values.subCategories.map((item, idx) => ({
    id: item.isNew ? undefined : item.id,
    name: item.name,
    description: item.description,
    order: idx,
    isActive: item.isActive,
  })),
});
