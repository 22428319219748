import { LdapUser } from "../types/User";
import { getInitials } from "./getInitials";

export const formatUserToDropdownOptions = (item: LdapUser) => ({
  value: item.email,
  label: item.fullName,
  details: item.email,
  avatarLabel: getInitials(item.fullName),
  pwcPpi: item.pwcPpi,
  firstName: item.firstName,
  lastName: item.lastName,
  phone: item.phone,
  pwcGuid: item.pwcGuid,
});
