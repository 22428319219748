import { Button, Select } from "@appkit4/react-components";
import { SubTemplateGuidance } from "../../types/SubTemplateGuidance";
import { Entity } from "../../../../../types/Entity";
import { getSubStepsToSteps } from "../../constants/getCreateDocumentSteps";
import { FieldError } from "../../../../../components/FieldError";
import styles from "./Guidances.module.scss";

interface Props {
  guidanceOptions: Entity[];
  guidance: SubTemplateGuidance;
  stepOptions: Entity[];
  subStepOptions: Entity[];
  onChange: (value: SubTemplateGuidance) => void;
  onDelete: (id: string) => void;
  errors?: Record<string, string | undefined>;
}

export const Guidances = ({
  guidanceOptions,
  guidance,
  stepOptions,
  subStepOptions,
  errors,
  onChange,
  onDelete,
}: Props) => {
  return (
    <div className="ap-mb-spacing-4">
      <div className={styles.wrapper}>
        <Select
          placeholder="Select Guidance"
          dropdownRenderMode="portal"
          className="ap-full-width"
          data={guidanceOptions}
          onSelect={(value) =>
            onChange({ ...guidance, guidanceId: value as string })
          }
          value={guidance.guidanceId}
          error={!!errors?.guidanceId}
          valueKey="id"
          labelKey="name"
        />
        <Select
          placeholder="Select Step"
          dropdownRenderMode="portal"
          className="ap-full-width"
          data={stepOptions}
          onSelect={(value) => {
            onChange({
              ...guidance,
              stepName: value as string,
              stepSection:
                !!guidance.stepName && value !== guidance.stepName
                  ? ""
                  : guidance.stepSection,
            });
          }}
          value={guidance.stepName}
          valueKey="id"
          labelKey="name"
        />

        <Select
          placeholder="Select SubStep"
          dropdownRenderMode="portal"
          className="ap-full-width"
          dropdownAlwaysDown={false}
          data={subStepOptions}
          disabled={!guidance.stepName || !subStepOptions}
          onSelect={(value) =>
            onChange({ ...guidance, stepSection: value as string })
          }
          value={guidance.stepSection}
          valueKey="id"
          labelKey="name"
        />

        <Button
          add
          kind="text"
          icon={`icon-delete-outline`}
          className="ap-text-neutral-10"
          onClick={() => onDelete(guidance.id)}
        />
      </div>

      {errors?.stepName && <FieldError message={errors?.stepName} />}
    </div>
  );
};
